import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { validatePage, verifySecurityAnswer } from "../store/actions/qaActions";
import { isRequestLink, storeTokenInMemory } from "../helper/HelperFunctions";
import { logger } from "../App";
import { LogEventConstants, QAPageConstants } from "../helper/Constants";
import { accessLinkParams } from "./SendLink";
import { useHistory, useParams } from "react-router";
import { SecurityShieldIcon } from "../common/icons/svgIcons";

interface IQAValidation {
  securityQuestion: string;
  securityAnswer: string;
  IsAuthenticateEnabled: boolean;
}

const QAValidation: React.FC = (props: any) => {
  const pageTitle = "QAValidation page";
  const history = useHistory();
  const recipientInfo = useSelector(
    (appState: ApplicationState) => appState.sendLink.recipientInfo
  );
  const [state, setState] = React.useState<IQAValidation>({
    securityQuestion: recipientInfo.securityQuestion,
    securityAnswer: "",
    IsAuthenticateEnabled: false,
  });
  const [validationMessage, setValidationMessage] = React.useState<string>("");
  const dispatch = useDispatch();
  const { clientId }: accessLinkParams = useParams();

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
        page: pageTitle,
        clientId: clientId,
        recipientInfo: JSON.stringify(recipientInfo),
      })
    );
    dispatch(validatePage(clientId, handleSuccess, handleError, props.type));
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        //Go Back to Verify Screen
        history.replace(`/${!isRequestLink(props.type) ? "SendLinkRedirect" : "DocumentRequest"}/${props.previousLocationClientId}`)
        props.setActiveMenu("Verify");
        props.setActiveMenuIndex(0);
      }
    }
  });

  const handleQAVerification = (result: any) => {
    storeTokenInMemory(result.messageGuid, result.accessToken);
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: answer verification finished and redirecting to download`, {
        page: pageTitle,
        clientId: clientId,
        recipientInfo: JSON.stringify(recipientInfo),
        messageGuid: result.messageGuid,
        answerInput: state.securityAnswer
      })
    );
    props.setActiveMenu(isRequestLink(props.type) ? "Upload" : "Download");
    props.setActiveMenuIndex(2);
  };

  const handleSecurityAnswerChange = (e: any) => {
    let securityAns = e.target.value;
    setState({
      ...state,
      securityAnswer: securityAns,
      IsAuthenticateEnabled: securityAns?.trim().length > 0 ? true : false,
    });
  };

  const handleError = (result: any) => {
    setState({
      ...state,
      securityAnswer: "",
    });
    setState((state) => ({ ...state, IsAuthenticateEnabled: false}));
    if (result.errorCode && result.errorCode === "ERROR_INVALID_ANSWER") {
      setValidationMessage(result.errorDescription);
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: answer verification failed for incorrect answer`, {
          page: pageTitle,
          clientId: clientId,
          recipientInfo: JSON.stringify(recipientInfo),
          answerInput: state.securityAnswer
        })
      );
    } else {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: page validation fail`, {
          page: pageTitle,
          clientId: clientId,
          recipientInfo: JSON.stringify(recipientInfo),
        })
      );
      history.push("/Error");
    }
  };

  const handleSuccess = (data: any) => {
    setState({
      ...state,
      securityQuestion: data.recipient.securityQuestion || data.recipient.question,
    });
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: validation success`, {
        page: pageTitle,
        clientId: clientId,
        recipientInfo: JSON.stringify(recipientInfo),
      })
    );
  };

  const handleSecurityAnswerVerify = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (state.IsAuthenticateEnabled && state.securityAnswer?.trim().length > 0) {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: answer verification started`, {
          page: pageTitle,
          clientId: clientId,
          recipientInfo: JSON.stringify(recipientInfo),
          answerInput: state.securityAnswer?.trim()
        })
      );
      dispatch(
        verifySecurityAnswer(
          state.securityAnswer?.trim(),
          clientId,
          handleQAVerification,
          handleError,
          props.type
        )
      );
    }
  };

  return (
    <Form onSubmit={handleSecurityAnswerVerify}>

      <div className="validation-container">
        <SecurityShieldIcon />
        <h4 className="card-title">{QAPageConstants.TITLE}</h4>

        <div>
          <span className="card-sub-title">
            {isRequestLink(props.type) ? QAPageConstants.QAHelperTextUpload : QAPageConstants.QAHelperTextDownload}
          </span>
        </div>
        <div className="question-text">
          <span>{state.securityQuestion}</span>
        </div>
        <div className="input-container">
          <input
            className={`primary-input ${validationMessage?.length > 0 && state.securityAnswer?.length === 0 ? "error" : ""}`}
            placeholder={QAPageConstants.ANSWER_INPUT_PLACEHOLDER}
            value={state.securityAnswer}
            onChange={handleSecurityAnswerChange}
          />
          {validationMessage && <div className="invalid-feedback">
            {validationMessage}
          </div>}
        </div>
        <div>
          <Button
            variant="primary"
            className="button-primary-blue-2"
            size="sm"
            onClick={handleSecurityAnswerVerify}
            placeholder={QAPageConstants.ANSWER_INPUT_PLACEHOLDER}
            disabled={!state.IsAuthenticateEnabled}
          >
            {QAPageConstants.AUTHENTICATE_BUTTON_TITLE}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default QAValidation;
