import moment from 'moment';
import { IDropOffRecipient } from "../core/viewModels/DropffModel";
import { LinkType } from "./Constants";

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export var inMemoryToken: Map<string, string> = new Map();
export function storeTokenInMemory(messageGuid: string, token: string) {
    inMemoryToken = new Map();
    inMemoryToken.set(messageGuid, token);
};

export const getUserIdFromEmail = (data: IDropOffRecipient[], email: string) => {
    let userId = 0;
    for(let i=0;i<data.length;i++){
      if(data[i].emailAddress === email && data[i].userId){
        userId = data[i].userId ?? 0;
        break;
      }
    }
    return userId;
};

export const checkIfUrlMatchesAnyRoute = (url: string, route: string) => {
  return url.toLowerCase().includes(route);
};

export const isRequestLink = (type: LinkType) => type === LinkType.DocumentRequestLink;

export const getFormattedData = (date: string) => moment(date).format("MM/DD/YYYY");

export const getFileName=(name:string):string=>{
  const pattern = /[\\/:*?"<>|\u00A0]/g;
  return name.replace(pattern, "_");
};