import { Action, Reducer } from "redux";
import { ErrorAction } from "../actions/errorActions";
import { actionTypes } from "../types/errorTypes";

export interface ErrorState {
    message: string;
}

let initialErrorState: ErrorState = { message: "" };

type DispatchAction = ErrorAction;

export const reducer: Reducer<ErrorState> = (state: ErrorState = initialErrorState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.SET_ERROR_MSG:
            return {
                message: action.data
            } as ErrorState;

        default:
            return state || initialErrorState;
    }
}