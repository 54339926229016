import { Action, Reducer } from "redux";
import { IDropOffPageInfo, IDropOffRecipient, IDropOffSettings, initialDropOffSettings } from "../../core/viewModels/DropffModel";
import { DispatchActions } from "../actions/dropOffActions";
import { actionTypes } from "../types/dropOffTypes";
import produce from "immer";

export interface IDropOffState {
    isSending: boolean,
    dropOffSettings: IDropOffSettings,
    dropOffPageInfo: IDropOffPageInfo
}

const initialDropOffPage: IDropOffState = {
  isSending: false,
  dropOffSettings: initialDropOffSettings,
  dropOffPageInfo: { personalDropOffUserId: 0 },
};

export const reducer: Reducer<IDropOffState> = produce(
  (draftState: IDropOffState = initialDropOffPage, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
      case actionTypes.COMPLETED_DROPOFF_SETTINGS:  
      case actionTypes.REQUEST_DROPOFF_SETTINGS:
        break;
      case actionTypes.RECEIVED_DROPOFF_SETTINGS:
        draftState.dropOffSettings = action.payload;
        break;
      case actionTypes.REQUEST_SEND_DROPOFF_MESSAGE:
        draftState.isSending = true;
        return draftState;

      case actionTypes.COMPLETED_SEND_DROPOFF_MESSAGE:
      case actionTypes.RECEIVED_SEND_DROPOFF_MESSAGE:
        draftState.isSending = false;
        return draftState;
      case actionTypes.REQUEST_DROP_OFF_PAGE_INFO:
        break;
      case actionTypes.RECEIVED_DROP_OFF_PAGE_INFO:
        draftState.dropOffPageInfo = action.payload;
        break;  
    }
    return draftState;
  }
);