import React, { FC } from "react";
import { ExchangeWhiteLogo } from "../../svg/IconCollection";
import ProfileDropdown from "./ProfileDropdown";
import { IHeaderInfo } from "../../core/viewModels/HeaderInfo";

interface IHeaderProps {
    headerInfo: IHeaderInfo;
}

const Header: FC<IHeaderProps> = ({ headerInfo }) => <header className="layout-header vertical-horizontal-center">
    <div className="header-logo-name-container">
        <ExchangeWhiteLogo/>
        <div className="separator-line"></div>
        <span className="company-name">{headerInfo.companyName}</span>
    </div>
    <ProfileDropdown headerInfo={headerInfo} />
</header>;

export default Header;