import React from "react";
import { IFileUploadModel } from "../../core/viewModels/FileModel";
import AttachmentListItem from "./AttachmentListItem";

interface IAttachmentListProps {
  attachments: IFileUploadModel[];
  onRemoveAttachment: (guid: string) => void;
}

const AttachmentList: React.FC<IAttachmentListProps> = (props) => {
  return (
    <div className="attachments">
      {props.attachments &&
        props.attachments.map((item: IFileUploadModel, index: number) => {
          return (
            <AttachmentListItem
              key={index}
              attachment={item}
              onRemoveAttachment={props.onRemoveAttachment}
            />
          );
        })}
    </div>
  );
};

export default AttachmentList;
