export enum AuthenticationMethod {
  UNKNOWN = -1,
  ACCESSCODE = 0,
  LOGINPASSWORD = 1,
  QUESTIONANSWER = 2,
  SENDSECUREPDF = 3,
}

export enum MessageSource {
  UNKNOWN = -1,
  MAILBOX = 0,
  DROPOFF = 1,
  WEBADDIN = 2,
}

export enum ValidationType {
  INVALID = 0,
  VALID = 1,
  WARNING = 2,
}

export enum SortOrder {
  ASC = 1,
  DESC,
}

export enum RecipientType {
  ToRecipient = 1,
  CcRecipient = 2,
}

export enum NotifierTypes {
  Error,
  Success,
  Warning,
  Info,
  None
}
export enum DropOffType {
  DropOff = 'dropOff',
  FolderDropOff = 'folderDropOff',
  SendLink = 'SendLink',
}
