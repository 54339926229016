export const DisplayError = [
    "ERROR_MESSAGE_CANCELLED",
    "ERROR_MESSAGE_EXPIRED",
    "ERROR_INVALID_LINK",
    "LINK_LOCKED",
    "ERROR_INVALID_OTP",
    "ERROR_INVALID_OTP_DROPOFF",
    "OTP_EXPIRED",
    "ERROR_INVALID_ANSWER",
    "ACCESS_CODE_NOT_EXISTS",
    "ERROR_RECIPIENT_LINK_LOCKED"
];

export const SendLinkConstants = {
    VERIFY_EMAIL_TITLE: "Verify Your Email",
    VERIFY_EMAIL_HELPER_TEXT: "Please enter the email address \nwhere you received the file #linkType link.",
    EMAIL_INPUT_PLACEHOLDER: "Enter your email address",
    VERIFY_BUTTON_TEXT: "Verify",
    INVALID_EMAIL_ERROR_TEXT: "Invalid email address. Your link will be locked ",
    ErrorMessage: {
        RetrieveFailed: "Unable to fetch recipient details",
        ServerError: "Internal Server Error, please refresh and try again",
        InvalidRecipient: "Invalid email address. Please try again!",
        InvalidLink: "The link to access your file(s) has been locked. Please contact your firm for further assistance.",
    },
};

export const OTPPageConstants = {
    TITLE: "Verify Your Access Code",
    AUTHENTICATE_BUTTON_TITLE: "Authenticate",
    RESEND_BUTTON_TITLE: "Resend Code",
    OTP_EXPIRED_WARNING:"Your One-Time Access Code has expired.",
    SPAM_WARNING: "If you did not receive it yet, please check your junk/spam folder.",
    SuccessMessage: {
        OTPGenerateSuccess: "OTP generated successfully and sent  to your email",
        ResentCodeSuccess:
            "A one-time access code has been resent to your email address successfully.",
    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for OTP",
        serverError: "Internal Server Error, please try to refresh",
        OTPGenerateFailed: "Unable to generate OTP,please try again",
        OTPExpired: "Entered OTP has expired",
        OTPError: "Please enter a valid OTP",
    },
    OtpSentMessageDownload:
        "Please enter the One-Time Access Code \nsent to your email address.",
    OtpSentMessageUpload:
        "Please enter the One-Time Access Code \nsent to your email address.",
    OtpExpiryMessage:
        "Expires in 20 minutes.",
    OtpLength: 8,
};

export const QAPageConstants = {
    TITLE: "Answer Your Security Question",
    ANSWER_INPUT_PLACEHOLDER: "Enter your answer",
    AUTHENTICATE_BUTTON_TITLE: "Authenticate",
    QAHelperTextDownload: "To download your file(s), kindly provide \nthe answer to the question below.",
    QAHelperTextUpload: "To upload your file(s), kindly provide \nthe answer to the question below.",
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for Security Answer",
        serverError: "Internal Server Error, Please try to refresh",
        OTPError: "Please Enter a Valid Security Answer",
    },
};

export const DownloadPageConstants = {
    PAGE_TITLE: "FileDownload page",
    TITLE: "File Download",
    FILES_READY_TEXT: "Files To Download",
    MESSAGE_MODAL_TITLE: "Message from CPA",
    MESSAGE_MODAL_CONFIRM_BUTTON: "OK",
    MESSAGE_MODAL_ALERT_MESSAGE: "Please Note! Message Text here",
    MESSAGE_ICON_TOOLTIP: "Message from CPA",
    DOWNLOAD_BUTTON_TEXT: "Download",
    DOWNLOAD_ALL_BUTTON_TEXT: "Download All",
    DOWNLOAD_ERROR_MESSAGE: "Unable to download file(s), please try again",
    ErrorMessage: {
        serverError: "Internal Server Error, Please try to refresh",
    },
    FilesNotAvailableForDownload: "Sorry, file(s) not available for download. Please contact <sendername> to resend it."
};

export const HeaderConstants = {
    ErrorMessage: {
        RetrieveFailed: "Unable to fetch details, please refresh and try again",
    },
};

export const FileConstants = {
    MaximumFileSize: "3221225472",
    MaximumTotalFileSize: 3221225472,
};

export const ValidationContants = {
    FirstNameWarning: "Please enter first name.",
    LastNameWarning: "Please enter last name.",
    EmailAdrressWarning: "Please enter email address.",
    ValidEmailAddressWarning: "Please enter valid  email address.",
    ValidIdentityServerEmailAddressWarning:
        "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
    FileSizeExceeded:
        "Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.",
};

export const ThemeConstants = {
    ICON_DISABLE_COLOR: "#929292",
    SIDEMENU_ICON_COLOR: "#f7f6f6",
    SELECT_DROPDOWN_STYLES: {
        input: {
            border: "none",
        },
    },
};

export const AccessModalConstants = {
    MODAL_TITLE: "Access Code Required!",
    EXPIRY_MESSAGE:
        "Expires in 20 minutes.",
    CARD_TITLE: "Enter the Access Code",
    CARD_SUBTITLE: "Please enter the + One-Time Access Code + \nsent to your email address ",
    AUTHENTICATE_BUTTON_TEXT: "Authenticate",
    RESEND_BUTTON_TEXT: "Resend Code",
    EXCEED_MAX_ATTEMPT_WARNING: "Exceeded attempts. Click 'Resend Code' for a new one.",
    INCORRECT_OTP_WARNING: "Incorrect code! Please try again.",
};

export const PlaceholderText = {
    NO_MESSAGE: "No Message to display",
    NO_FILES: "No files to download.",
};

export const DropOffConstants = {
    DROP_OFF: "/dropoff",
    FOLDER_DROP_OFF: "/folderdropoff",
    SEND_LINK: "/sendlinkredirect",
    DOCUMENT_REQUEST: "/documentrequest",
    SUBJECT_MAXLENGTH: 250,
    FIRST_NAME_MAXLENGTH: 50,
    MESSAGE_MAXLENGTH: 50000,
    INVALID_EMAIL_FORMAT: "Email is invalid.",
    RECIPIENTS_WARNING: "Please select at least one recipient.",
    EMPTY_FIELD_WARNING: "This field can't be empty.",
    WITHOUT_ATTACHMENT_WARNING:
        "Do you want to send this message without attachment(s)?",
    UPLOAD_PROGRESS_MESSAGE: "Please wait. Upload in progress.",
    NO_USER_FOUND_MESSAGE: "No users found.",
    VALIDATE_DROP_OFF_LINK_MESSAGE: "Please wait while validating link",
    DROP_OFF_SUCCESS_MESSAGE: "Message sent successfully.",
    ERROR_DROP_OFF: "ERROR_DROP_OFF",
    MESSAGE_SEND_FAILED: "There was an error while sending the message. Please try again.",
    PLACEHOLDER_EMAIL: "Enter your email address",
    PLACEHOLDER_FIRST_NAME: "Enter your first name",
    PLACEHOLDER_LAST_NAME: "Enter your last name",
    LABEL_EMAIL: "Email",
    LABEL_FIRST_NAME: "First Name",
    LABEL_LAST_NAME: "Last Name",
    EMAIL_EMPTY_WARNING: "Please enter sender's email.",
    FIRST_NAME_EMPTY_WARNING: "Please enter sender's first name.",
    LAST_NAME_EMPTY_WARNING: "Please enter sender's last name.",
    TITLE: "Send Us File(s)",
    FROM_TITLE: "From",
    REMEMBER_ME: "Remember Me",
    TO_TITLE: "To",
    RECIPIENTS_TITLE: "Recipients",
    SUBJECT_TITLE: "Subject",
    SEND_TEXT: "Send",
    WARNING_SENDER_DETAILS: "Please enter all sender details.",
    SUBJECT_EMPTY_WARNING: "Subject can't be empty.",
    MESSAGE_EMPTY_WARNING: "Message can't be empty.",
    ATTACHMENT_UPLOAD_INCOMPLETE_WARNING: "Upload in progress. Please wait.",
    ATTACH_FILES: "Attach File(s)",
    DRAG_AND_DROP_TEXT: "Drag file to this area to upload",
    DRAG_AND_DROP_SUB_TEXT: "Support for a single or bulk upload.",
    SENDER_EMAIL: "senderEmail",
    SENDER_FIRST_NAME: "senderFirstName",
    SENDER_LAST_NAME: "senderLastName",
    LOADING_LOADER_TEXT: "Loading...",
    SENDING_MESSAGE_LOADER_TEXT: "Please wait. Upload in progress."
};

export const AppInsightsConstants = {
    Role: "SSE-TaxpayerUI",
    Product: "SafeSend Exchange",
};

export const LogEventConstants = {
    Common: {
        Open: "Open",
        Close: "Close",
        Checked: "Checked",
        Unchecked: "Unchecked"
    },
    Dropoff: {
        AccessCodeModal: {
            Title: "AccessCode modal",
        },
        SendWithoutAttachmentConfirmationModal: {
            Title: "Send without attachment confirmation modal"
        },
        FileUploader: {
            PageTitle: "File Uploader",
        }
    }
};

export const AccessCardMenuItems = ["Verify", "Authenticate", "Download"];

export const DropoffSenderForm = {
    EMAIL: 1,
    FIRST_NAME: 2,
    LAST_NAME: 3
};

export enum LinkType {
    SendLink = 1,
    DocumentRequestLink = 2
}

export const DocumentRequestMeuItems = ["Verify", "Authenticate", "Upload"];

export const UploadConstants = {
    DELETE_TITLE: "Delete All File(s)",
    DELETE_TEXT: "This action will delete all the files in this document. Do you wish to continue?",
    CONFIRMATION_TITLE: "Confirmation",
    CONFIRMATION_TEXT_ALL_UPLOADED: "On confirmation you will not be able to make any further changes.\nDo you wish to continue?",
    UPLOAD_DONE_TEXT: "Submit Request",
    PARTIAL_UPLOAD_DONE_SUCCESS: "Document(s) submitted successfully!",
    FULLY_UPLOAD_DONE_SUCCESS: "Request submitted successfully!",
    COMPLETED_REQUEST_TITLE_1: "This Document Request List is complete!",
    COMPLETED_REQUEST_TITLE_2: "You have no further actions to take.",
    OPEN_REQUEST_TITLE: "You have #files# document(s) to Upload!",
    REQUESTED_FILES_TEXT: "Requested Documents",
    MESSAGE_ICON_TOOLTIP: "Message from CPA",
    MESSAGE_MODAL_TITLE: "Message from CPA",
    MESSAGE_MODAL_CONFIRM_BUTTON: "OK",
    UPLOAD_BUTTON_TEXT: "Upload",
    DELETE_BUTTON_TEXT: "Delete",
    DELETE_ALL_BUTTON_TEXT: "Delete All",
    MAX_FILE_SIZE_TOOLTIP: "Maximum file size for all upload(s) combined: 3GB",
    UPLOAD_FILE_ERROR_CODE:"DR_UPLOAD_FAILED",
    COLLAPSE_ALL_TEXT: "Collapse All",
    EXPAND_ALL_TEXT: "Expand All",
    FILE_DELETE_SUCCESS: "File deleted successfully.",
    FILES_DELETE_SUCCESS: "File(s) deleted successfully.",
    UPLOAD_IN_PROCESS: "Upload in progress. Please wait.",
};

export const LayoutConstants = {
    CONTACT_INFO: "Contact Information",
    COPYRIGHTS_TEXT: "Copyright © {year} cPaperless LLC",
    PRIVACY_POLICY_TEXT: "Privacy Policy",
    PRIVACY_POLICY_URL: "https://safesend.com/legal-policies/privacy-statement.php",
    TERMS_TEXT: "Terms of service",
    TERMS_URL: "https://safesend.com/legal-policies/terms-of-use.php",
    ERROR_PAGE_TEXT: "This link is no longer valid. \nPlease contact your firm for further assistance."
};

export enum DropOffLinkType {
    DropOff = 1,
    FolderDropOff = 2
}

export const DropoffErrorConstants = {
    RECIPIENT_NOT_FOUND : "No dropoff recipient found",
    FOLDER_DELETED : "Folder deleted"
};

