import { Action, Reducer } from "redux";
import { actionTypes } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "../actions/notificationActions";

export interface NotificationState {
  message: string;
  type: StatusType;
  identifier: Date;
}

type DispatchAction = NotificationAction;

const unloadedState: NotificationState = {} as NotificationState;

export const reducer: Reducer<NotificationState> = (
  state: NotificationState = unloadedState,
  incomingAction: Action
) => {
    const action = incomingAction as DispatchAction;
  switch (action.type) {
    case actionTypes.NOTIFICATION:
      const notification: NotificationState = { ...state };
      notification.message = action.statusMessage;
      notification.type = action.statusType;
      notification.identifier = new Date();
      return notification;
    default:
  }
  return state || unloadedState;
};
