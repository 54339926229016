import {
    AuthenticationMethod
} from "../common/enums";

export interface ISentMessageModel {
    sentMessageId: number;
    sentMessageGuid: string;
    subject: string;
    message: string;
    senderId: number;
    senderEmail: string;
    senderFirstName: string;
    senderLastName: string;
    //notifyWhenDownloaded: number;
    expiresDays: number;
    sentDate: Date;
    authenticationMethod: AuthenticationMethod;
    //messageSource: MessageSource;
    secureMessage: string;
}

export const initialSentMessage: ISentMessageModel = {
    sentMessageId: 0,
    sentMessageGuid: "",
    senderId: 0,
    senderEmail: "",
    senderFirstName: "",
    senderLastName: "",
    subject: "",
    message: "",
    sentDate: new Date(),
    expiresDays: 0,
    authenticationMethod: AuthenticationMethod.UNKNOWN,
    secureMessage: ""
};
