import React from "react";
import { IFileUploadModel } from "../../core/viewModels/FileModel";
import { CloseXButton } from "../../common/icons/svgIcons";

interface IAttachmentListItemProps {
  attachment: IFileUploadModel;
  onRemoveAttachment: (guid: string) => void;
}
const AttachmentListItem: React.FC<IAttachmentListItemProps> = (props) => {
  return (
    <div className="attachment-card">
      <div
        className="attachment-detail ellipsis"
        title={props.attachment.file.name}
      >
        {props.attachment.file.name}
      </div>
      <div className="attachment-remove">
        {props.attachment.isUploaded ? (
          <span
            className=""
            onClick={() => props.onRemoveAttachment(props.attachment.guid)}
          >
            <CloseXButton width={10} height={10}/>
          </span>
        ) : (
          <span className="fas fa-spin fa-circle-notch" style={{fontSize: "10px"}}></span>
        )}
      </div>
      {props.attachment.uploadProgress < 1 && (
        <div
          className="upload-progress-bar"
          style={{ width: `${props.attachment.uploadProgress * 100}%` }}
        ></div>
      )}
    </div>
  );
};

export default AttachmentListItem;
