import axios from "axios";
import { DisplayError, DownloadPageConstants } from "../../helper/Constants";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { IFileModel } from "../../core/viewModels/FileModel";
import { ApplicationState, AppThunkAction } from "../index";
import { ErrorAction } from "./errorActions";
import { StatusType, NotificationAction } from "./notificationActions";
import { TelemetryLogger } from "../../common/Logger/AppInsights";

const logger = TelemetryLogger.getInstance();

type KnownActions = ErrorAction | NotificationAction;

export const downloadFile =
  (
    id: string,
    fileId: number,
    fileGuid: string,
    fileName: string,
    handleSuccsess?: (uri: string) => void,
    handleError?: () => void
  ): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    return axios
      .post<any>(
          `/api/download/File/${id}?physicalPath=${fileGuid}&fileId=${fileId}&fileName=${encodeURIComponent(fileName)}`,{}
      )
      .then(function (response: any) {
        let result = response.data;
        if (result) {
          handleSuccsess && handleSuccsess(result);
        } else {
          let errorDesc = result.errorDescription;
          let errorCode = result.errorCode;

          if (errorCode && DisplayError.includes(errorCode)) {
            dispatch({
              type: errorTypes.SET_ERROR_MSG,
              data: errorDesc,
            });

            handleError && handleError();
          } else {
            dispatch({
              type: notificationTypes.NOTIFICATION,
              statusMessage: errorDesc,
              statusType: StatusType.Error,
            });
          }
        }
      })
      .catch(function (error: any) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: DownloadPageConstants.ErrorMessage.serverError,
          statusType: StatusType.Error,
        });
        let state: ApplicationState = getState();
        logger.trackError(
          `downloadFile failed with error ${error.message} for sent message recipient: ${state.sendLink.recipientInfo.sentMessageRecipientId}`
        );
      });
  };

export const downloadFiles =
  (
    id: string,
    files: IFileModel[],
    handleSuccess?: (uri: string) => void,
    handleError?: () => void
  ): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;
    return axios
      .post<any>(
        `/api/download/Files/${id}?timezoneOffsetInHours=${timezoneOffsetInHours}`,
        files
      )
      .then(function (response: any) {
        let result = response.data;
        if (result) {
          handleSuccess && handleSuccess(result);
        } else {
          let errorDesc = result.errorDescription;
          let errorCode = result.errorCode;

          if (errorCode && DisplayError.includes(errorCode)) {
            dispatch({
              type: errorTypes.SET_ERROR_MSG,
              data: errorDesc,
            });

            handleError && handleError();
          } else {
            dispatch({
              type: notificationTypes.NOTIFICATION,
              statusMessage: errorDesc,
              statusType: StatusType.Error,
            });
          }
        }
      })
      .catch(function (error: any) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: DownloadPageConstants.ErrorMessage.serverError,
          statusType: StatusType.Error,
        });
        logger.trackError(
          `downloadFiles failed with error ${
            error.message
          } for sent message recipient: ${
            getState().sendLink.recipientInfo.sentMessageRecipientId
          }`
        );
      });
  };

export const getMessageDetails =
  (
    messageId: string,
    handleMessageDetails?: (messageDetails: any) => void,
    handleError?: () => void
  ): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    return axios
      .post<any>(`/api/Download/GetMessageDetail/${messageId}`)
      .then(function (response: any) {
        let result = response.data;
        if (result.isSuccess) {
          handleMessageDetails && handleMessageDetails(result.data);
        } else {
          let errorDesc = result.errorDescription;
          let errorCode = result.errorCode;

          if (errorCode && DisplayError.includes(errorCode)) {
            dispatch({
              type: errorTypes.SET_ERROR_MSG,
              data: errorDesc,
            });

            handleError && handleError();
          } else {
            dispatch({
              type: notificationTypes.NOTIFICATION,
              statusMessage: errorDesc,
              statusType: StatusType.Error,
            });
          }
        }
      })
      .catch(function (error: any) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: DownloadPageConstants.ErrorMessage.serverError,
          statusType: StatusType.Error,
        });
        logger.trackError(
          `getMessageDetails failed with error ${
            error.message
          } for sent message: ${messageId} and sent message recipient: ${
            getState().sendLink.recipientInfo.sentMessageRecipientId
          }`
        );
      });
  };
