import axios from "axios";
import { TelemetryLogger } from "../Logger/AppInsights";
import { DropOffType } from "../../core/common/enums";

const logger = TelemetryLogger.getInstance();
export const addWalkMeScript = (clientId: string, dropOffType: DropOffType) => {
    axios.
        get<any>(`/api/${dropOffType}/GetWalkMeScript/${clientId}`)
        .then(function (response: any) {
            if (response.status == 200) {
                if (!(window as any)._walkmeConfig?.smartLoad) {
                    if (response && response.data.length > 0) {
                        const scriptElement = document.createElement("script");
                        scriptElement.type = "text/javascript";
                        scriptElement.async = true;
                        scriptElement.text = response.data;
                        document.head.appendChild(scriptElement);
                        (window as any).loadWalkMe && (window as any).loadWalkMe();
                    }
                }
            }
        })
        .catch(function (error: any) {
            logger.trackError(`Error while executing walk me script ${error}`);
        });
};