import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import OtpInputControl from "./common/OtpInputControl";
import {
  generateOTP,
  validatePage,
  verifyOTP,
} from "../store/actions/otpActions";
import { AppNotifier } from "../helper/AppNotifier";
import { LogEventConstants, OTPPageConstants } from "../helper/Constants";
import { isRequestLink, storeTokenInMemory } from "../helper/HelperFunctions";
import { logger } from "../App";
import { accessLinkParams } from "./SendLink";
import { useHistory, useParams } from "react-router";
import { SecurityShieldIcon } from "../common/icons/svgIcons";

export interface IOTPValidation {
  otpVal: string;
  otpInvalidMessage?: string;
}

const OTPValidation: React.FC = (props: any) => {
  const pageTitle = "OTPValidation page";
  const history = useHistory();
  const [state, setState] = React.useState<IOTPValidation>({
    otpVal: "",
    otpInvalidMessage: "",
  });

  const continueState = state.otpVal.length === 8;

  const dispatch = useDispatch();

  const { clientId }: accessLinkParams = useParams();
  const type = props.type;

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    dispatch(validatePage(clientId, handleSuccess, handleError, props.type));
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        //Go Back to Verify Screen
        history.replace(`/${isRequestLink(type) ? "DocumentRequest" : "SendLinkRedirect"}/${props.previousLocationClientId}`)
        props.setActiveMenu("Verify");
        props.setActiveMenuIndex(0);
      }
    }
  });

  const handleOTPChange = (otp: string) => {
    setState((state) => ({ ...state, otpVal: otp }));
  };

  const handleOTPVerification = (result: any) => {
    storeTokenInMemory(result.data.messageGuid, result.data.accessToken);
    logger.trackEvent(
      logger.buildEvent(
        `${pageTitle}: otp verification finished and redirecting to download`,
        {
          page: pageTitle,
          clientId: clientId,
          messageGuid: result.messageGuid,
          otpInput: state.otpVal,
        }
      )
    );
    props.setActiveMenu(isRequestLink(type) ? "Upload" : "Download");
    props.setActiveMenuIndex(2);
  };

  const handleError = (result: any) => {
    setState((state) => ({ ...state, otpVal: "" }));
    if (
      result.errorCode &&
      (result.errorCode === "ERROR_INVALID_OTP" ||
        result.errorCode === "OTP_EXPIRED")
    ) {
      logger.trackEvent(
        logger.buildEvent(
          `${pageTitle}: otp verification failed for incorrect/expired otp`,
          {
            page: pageTitle,
            clientId: clientId,
            otpInput: state.otpVal,
            errorCode: result.errorCode,
          }
        )
      );
      result.errorCode === "OTP_EXPIRED" ? AppNotifier.Error(OTPPageConstants.OTP_EXPIRED_WARNING)
      : setState((state) => ({...state, otpInvalidMessage: result.errorDescription}));
    } else {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: otp validation fail`, {
          page: pageTitle,
          clientId: clientId,
          otpInput: state.otpVal,
        })
      );
      history.push("/Error");
    }
  };

  const handleSuccess = () => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: validation success`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    dispatch(generateOTP(clientId, props.type));
  };

  const handleOTPVerify = () => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: otp submitted`, {
        page: pageTitle,
        clientId: clientId,
        otpInput: state.otpVal,
      })
    );
    continueState &&
      dispatch(
        verifyOTP(state.otpVal, clientId, handleOTPVerification, handleError, props.type)
      );
  };

  const requestCode = () => {
    dispatch(generateOTP(clientId, props.type ,true));
  };

  return (
    <div className="validation-container">
      <SecurityShieldIcon />
      <h4 className="card-title">{OTPPageConstants.TITLE}</h4>

      <p className="card-sub-title">{OTPPageConstants.OtpSentMessageDownload}</p>
      <p className="otp-expiry-text">{OTPPageConstants.OtpExpiryMessage}</p>
      <OtpInputControl
        otp={state.otpVal}
        setOtp={handleOTPChange}
        isInvalid={state.otpInvalidMessage !== ""}
        errorText={state.otpInvalidMessage}
      />
      <div>
        <Button
          variant="primary"
          size="sm"
          className="button-primary-blue-2"
          disabled={!continueState}
          onClick={handleOTPVerify}
        >
          {OTPPageConstants.AUTHENTICATE_BUTTON_TITLE}
        </Button>
      </div>
      <span className="spam-text">{OTPPageConstants.SPAM_WARNING}</span>
      <Button className="resend-btn" id="btn-resend-code" variant="link" onClick={requestCode}>
        {OTPPageConstants.RESEND_BUTTON_TITLE}
      </Button>
    </div>
  );
};

export default OTPValidation;
