import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface TinyMCEComponentProps {
  messageBody: string;
  changeStateTinymceBody: (event: any) => void;
  readOnly: number;
  key: string;
  onDragOver?: () => void;
}

const TinyMCEComponent: React.FC<TinyMCEComponentProps> = (props) => {
  const editorRef: any = useRef(null);
  const [messageBody] = useState(props.messageBody);

  const [showTinyMCE, setShowTinyMCE] = useState(true);

  useEffect(() => {
    const editor = editorRef?.current;
    if (editor?.readonly) {
      setShowTinyMCE(false);
    } 
  });

  if (!showTinyMCE) {
  return (
      <textarea className = "tinymce-textarea"
        value={props.messageBody}
        onChange={(e) => props.changeStateTinymceBody(e.target.value)}
      />
    );
  }

  return (
    <div>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        key={props.key}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={messageBody}
        onEditorChange={props.changeStateTinymceBody}
        init={{
          menubar: false,
          branding: false,
          statusbar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          toolbar:
            "bold italic textcolor backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }::-webkit-scrollbar {width: 8px;background-color: inherit;}::-webkit-scrollbar-track {box-shadow: inset 0 0 5px #f6f6f6;border-radius: 10px;}::-webkit-scrollbar-thumb {background: #0d6efd40;border-radius: 10px;}::-webkit-scrollbar-thumb:hover {background: #0973ba;}",
          setup: function (editor) {
            editor.on("dragover", function (e) {
              e.preventDefault();
              e.stopPropagation();
              props.onDragOver && props.onDragOver();
            });
          }
        }}
      />
    </div>
  );
};

export default TinyMCEComponent;
