import { actionTypes } from "../types/notificationTypes";

export enum StatusType {
    None,
    Error,
    Success,
    Warning,
}

export interface NotificationAction {
    type: actionTypes.NOTIFICATION;
    statusMessage: string;
    statusType: StatusType;
}