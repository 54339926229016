import React, { FC, useEffect, useState } from "react";
import { isValidEmailAddress } from "../helper/Validations";
import { logger } from "../App";
import { LinkType, LogEventConstants, SendLinkConstants } from "../helper/Constants";
import { EmailEnvelopeIcon } from "../common/icons/svgIcons";
import { isRequestLink } from "../helper/HelperFunctions";
import { Button, Form } from "react-bootstrap";
import { Colors } from "../styles/ColorConstants";

interface EmailPopupProps {
  onSubmit: (email: string) => void;
  validationMessage: string;
  clientId: string;
  type: LinkType;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const EmailPopup: FC<EmailPopupProps> = (props) => {
  const {email, setEmail} = props;

  const pageTitle = "Recipient Email Validation Popup";

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
        page: pageTitle,
        clientId: props.clientId,
      })
    );
  }, []);

  const handleEmailValidation: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: email verification submitted`, {
        page: pageTitle,
        clientId: props.clientId,
        inputEmail: email
      })
    );
    props.onSubmit(email);
  };
  return (
    <div className="email-popup-container">
      <Form noValidate validated={!(props.validationMessage?.length > 0)} onSubmit={handleEmailSubmit}>
        <EmailEnvelopeIcon
          width={80}
          height={80}
          fillColor={Colors.$navy}
        />
        <h4 className="card-title">{SendLinkConstants.VERIFY_EMAIL_TITLE}</h4>
        <p>
          {SendLinkConstants.VERIFY_EMAIL_HELPER_TEXT.replace("#linkType", isRequestLink(props.type) ? "upload " : "access ")}
        </p>
        <div className="input-container">
        <input
          className={`primary-input ${props.validationMessage?.length > 0 && email.length === 0 ? "error" : ""}`}
          type="text"
          placeholder={SendLinkConstants.EMAIL_INPUT_PLACEHOLDER}
          value={email}
          onChange={handleEmailValidation}
        />
        {props.validationMessage && <div className="invalid-feedback">
          {props.validationMessage}
          </div>}
        </div>
              
        <div>
          <Button
            disabled={!isValidEmailAddress(email)}
            className="button-primary-blue-2"
            type="submit"
            size="sm"
            variant="primary"
          >
            {SendLinkConstants.VERIFY_BUTTON_TEXT}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EmailPopup;
