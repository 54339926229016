export interface IDocumentRequestModel {
  documentRequestId: number;
  subject: string;
  message: string;
  status: DetailedStatus;
  year: number;
  updatedDate: Date;
  sentDate: Date;
  dueDate: Date;
  senderId: number;
  expiryDays: number;
  documentRequestGuid: string;
  authenticationMethod: AuthenticationMethod;
  recipient?: IDocumentRequestRecipient;
  requestedFiles?: IDocumentRequestFile[];
}

export enum DetailedStatus {
  NONE = 0,
  OPEN = 1,
  COMPLETED = 2,
  FAILED = 3,
}
export enum AuthenticationMethod {
  UNKNOWN = -1,
  ACCESSCODE = 0,
  LOGINPASSWORD = 1,
  QUESTIONANSWER = 2,
  SENDSECUREPDF = 3,
}

export interface IDocumentRequestRecipient {
  documentRequestRecipientId: number;
  documentRequestId: number;
  email: string;
  question?: string;
  answer?: string;
}

export interface IDocumentRequestFile {
  id: number;
  documentRequestFileId: number;
  name: string;
  description: string;
  documentRequestId?: number;
  documentCategoryId?: number;
  documentCategoryName: string;
  createdDate: Date;
  uploadedFiles: IDocumentRequestFileDetails[];
}

export interface IUploadTableData extends IDocumentRequestFile {
  disabled?: boolean;
}
export interface IDocumentRequestFileDetails {
  fileId: number;
  isDeleted: boolean;
  fileSize: number;
  fileGuid: string;
  isUploaded: boolean;
  uploadedDate: Date;
  fileName?: string;
  uploadProgress?: number;
}

export const initialDocumentRequestFileDetails: IDocumentRequestFileDetails = {
  fileId: 0,
  isDeleted: false,
  fileSize: 0,
  fileGuid: "",
  isUploaded: false,
  uploadedDate: new Date(),
  fileName: "",
  uploadProgress: 0,
};

export const initialDocumentState: IDocumentRequestModel = {
  documentRequestId: 0,
  subject: "",
  message: "",
  status: 0,
  year: 2023,
  sentDate: new Date(),
  updatedDate: new Date(),
  dueDate: new Date(),
  expiryDays: 0,
  senderId: 0,
  documentRequestGuid: "",
  authenticationMethod: AuthenticationMethod.ACCESSCODE,
  requestedFiles: [],
};
