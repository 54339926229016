import axios, { AxiosResponse } from "axios";

import { AppThunkAction } from "..";
import { ISentMessageModel } from "../../core/viewModels/SentMessageModel";
import { ISentMessageRecipientModel } from "../../core/viewModels/SentMessageRecipientModel";
import { actionTypes } from "../types/sendLinkTypes";
import { DisplayError, HeaderConstants, LinkType, SendLinkConstants } from "../../helper/Constants";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { ErrorAction } from "./errorActions";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "./notificationActions";
import { IHeaderInfo } from "../../core/viewModels/HeaderInfo";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { isRequestLink } from './../../helper/HelperFunctions';
import { DropOffType } from "../../core/common/enums";

export interface RequestRecipientInfoAction {
    type: actionTypes.REQUEST_RECIPIENT_INFO
}

export interface ReceivedRecipientInfoAction {
    type: actionTypes.RECEIVED_RECIPIENT_INFO;
    data: {
        recipient: ISentMessageRecipientModel;
        message: ISentMessageModel;
    };
}

export interface ReceivedHeaderInfoAction {
    type: actionTypes.RECEIVED_HEADER_INFO;
    data: IHeaderInfo;
}

export type DispatchActions = RequestRecipientInfoAction
    | ReceivedRecipientInfoAction
    | ReceivedHeaderInfoAction;

type KnownActions = DispatchActions | ErrorAction | NotificationAction;

const logger = TelemetryLogger.getInstance();

export const requestScreen = (clientId: string, handleSuccess?: (data: any) => void, handleError?: () => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_RECIPIENT_INFO });

        const APILink = isRequestLink(type) ? "api/RequestLink/ValidateLink/" : "api/SendLink/ValidateSendLink/";

        return axios.get<any>(APILink + clientId)
            .then(function (response: AxiosResponse<any>) {
                let result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVED_RECIPIENT_INFO,
                        data: result.data
                    });
                    handleSuccess(result.data);
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });
                        handleError();
                    }
                    else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        })
                    }
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SendLinkConstants.ErrorMessage.ServerError,
                    statusType: StatusType.Error
                });
                logger.trackError(
                    `requestScreen failed with error ${e.message} for clientId: ${clientId}`
                );

                handleError && handleError();
            }
            );
    }

export const validateRecipient = (clientId: string, recipientEmail: string, handleSuccess: (data: any) => void, handleError: (result: any) => void, type?: LinkType):
    AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_RECIPIENT_INFO });

        return axios.get<any>(`api/${isRequestLink(type) ? "RequestLink/ValidateRecipient" : "SendLink/ValidateRecipientAsync"}/${clientId}?recipientEmail=${recipientEmail}`)
            .then(function (response: AxiosResponse<any>) {
                let result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVED_RECIPIENT_INFO,
                        data: result.data
                    });
                    handleSuccess(result.data);
                }
                else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc
                        });
                        handleError(result);
                    }
                    else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        })
                    }
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: SendLinkConstants.ErrorMessage.ServerError,
                    statusType: StatusType.Error
                });
                logger.trackError(
                    `validateRecipient failed with error ${e.message} for clientId: ${clientId} and recipient email: ${recipientEmail}`
                );
            }
            );
    }

export const requestHeaderDetails = (clientId: string, dropOffType: DropOffType): AppThunkAction<any> => (dispatch, getState) => {

    const controller = dropOffType;
    return axios.get<any>(`/api/${controller}/GetHeaderInfo/${clientId}`)
        .then(function (response: AxiosResponse<any>) {
            let result = response.data;
            if (result) {
                dispatch({
                    type: actionTypes.RECEIVED_HEADER_INFO,
                    data: result
                });
            }
            else {
                let errorDesc = result.errorDescription;
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: errorDesc,
                    statusType: StatusType.Error
                })
            }
        })
        .catch((e: any) => {
            dispatch({
                type: notificationTypes.NOTIFICATION,
                statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
                statusType: StatusType.Error
            });
            logger.trackError(
                `requestHeaderDetails failed with error ${e.message} for clientId: ${clientId} and controller: ${controller}`
            );
        }
        );

}