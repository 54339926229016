import React, { FC } from "react";
import { Col } from "react-bootstrap";
import { CheckMarkIcon } from "../../common/icons/svgIcons";
import { Colors } from "../../styles/ColorConstants";

interface IStepWizardProps {
  menuKeys: string[],
  activeMenuIndex: number,
  className?: string
}

export const StepWizard: FC<IStepWizardProps> = ({
  menuKeys,
  activeMenuIndex,
  className

}) => {

  return (
    <div className={"step-wizard-container " + className}>
      {menuKeys && menuKeys.map((menu, index) => (<Col
        className="step-wizard-icons-container"
        key={menu + "-" + index}
        style={{ flex: index === 1 ? 0 : 1 }}>

        {index === 2 && <div
          className={`step-border ${activeMenuIndex === 2 ? "active-border" : ""}`}
        ></div>}
        <div className={`step-wizard-icons ${index !== activeMenuIndex && (index > activeMenuIndex) ? "disabled-access-step" : ""}`}>
          <span className="access-step">{index < activeMenuIndex
            ? <CheckMarkIcon
              fillColor={Colors.$sapphire}
            /> : index + 1}</span>
          <span className="menu-title" style={{fontWeight: index === activeMenuIndex ? 700 : 400}}>{menu}</span>
        </div>
        {index === 0 && <div
          className={`step-border ${activeMenuIndex >= 1 ? "active-border" : ""}`}
        ></div>}
      </Col>))}
    </div>
  )
}