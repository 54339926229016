import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";

const MobileView: FC = () => {
    const { companyName } = useSelector((appState: ApplicationState) => appState.sendLink.headerInfo);
    return <div className="mobile-view-container">
        <div>
            <h3>Hello!</h3>
            To drop your file(s) to <b>{companyName}</b>,<br />
            please access this link through a tablet/computer browser.
        </div>
    </div>
};

export default MobileView;