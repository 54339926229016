export interface IUserModel {
    firstName: string;
    middleName?: string;
    lastName: string;
    phoneNumber: string;
    extension?: string;
    emailAddress: string;
    countryCode?: string;
}

export const initialUserModel: IUserModel = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: ""
}