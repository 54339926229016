import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { LinkLockIcon } from '../../common/icons/svgIcons';
import "./Error.scss";
import { LayoutConstants } from '../../helper/Constants';

export const ErrorPage: React.FC = (props) => {

    const errorState = useSelector((appState: ApplicationState) => appState.errorState);

    const getErrorMessage = () => {
        if (errorState.message) {
            return errorState.message.split(".").join(".\n");
        }
        return LayoutConstants.ERROR_PAGE_TEXT;
    }
    return (
        <div className="content-wrapper">
            <div className="error-page">
                <div className="error-page-container">
                    <div className="message">
                        <LinkLockIcon />
                        <span>{(getErrorMessage())}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
