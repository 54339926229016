import React, { useEffect } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import { Markup } from "interweave";
import { useDispatch } from "react-redux";
import { MessageModal } from "./MessageModal";
import {
    downloadFile,
    downloadFiles,
    getMessageDetails,
} from "../store/actions/downloadActions";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { formatBytes } from "../helper/HelperFunctions";
import { DownloadPageConstants, PlaceholderText } from "../helper/Constants";
import { logger } from "../App";
import { useHistory, useParams } from "react-router";
import { accessLinkParams } from "./SendLink";
import { AttachmentIcon, ChatMessageIcon, DownloadIcon } from "../common/icons/svgIcons";
import { Alert } from "react-bootstrap";
import { AppNotifier } from "../helper/AppNotifier";

const handleDownload = (url: any) => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.style.display = "none";
    a.click();
    document.body.removeChild(a);
};
interface IFileDownloadProps {
    files?: any[];
    senderName?: string;
    message?: string;
    messageId?: number;
    senderEmail?: string;
    match?: any; //to get querystring value
    setActiveMenu?: React.Dispatch<React.SetStateAction<string>>;
    setActiveMenuIndex?: React.Dispatch<React.SetStateAction<number>>;
    allFileExist?: boolean;
    showMessageModal?: boolean;
    isFileListLoading?: boolean;
}

const FileDownload: React.FC<IFileDownloadProps> = (props) => {
    const pageTitle = DownloadPageConstants.PAGE_TITLE;
    const history = useHistory();
    const [state, setState] = React.useState<IFileDownloadProps>({
        files: [],
        senderName: "",
        message: "",
        messageId: 0,
        senderEmail: "",
        match: "",
        allFileExist: true,
        showMessageModal: false,
        isFileListLoading: true
    });

    const dispatch = useDispatch();
    const { clientId }: accessLinkParams = useParams();

    useEffect(() => {
        logger.trackPageView(pageTitle);
        logger.trackEvent(
            logger.buildEvent(`${pageTitle} loaded`, {
                page: pageTitle,
                clientId: clientId,
            })
        );
        setState((state) => {
            return {
                ...state, isFileListLoading: true
            }
        })
        dispatch(getMessageDetails(clientId, handleMessageDetails, handleError));

    }, []);

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                //Do not allow to go back on previous screen
                history.push(`/SendLinkRedirect/${clientId}`)
                props.setActiveMenu("Download");
                props.setActiveMenuIndex(2);
            }
        }
    });

    const handleMessageDetails = (data: any) => {
        setState({
            ...state,
            files: data?.mailMessage.attachments,
            senderName:
                data?.mailMessage.messageDetail.senderFirstName +
                " " +
                data?.mailMessage.messageDetail?.senderLastName,
            message: data?.mailMessage.messageDetail?.message,
            messageId: data?.mailMessage.messageDetail?.sentMessageId,
            senderEmail: data?.mailMessage.messageDetail?.senderEmail,
            allFileExist: data?.allFileExist,
            isFileListLoading: false
        });
    };

    const columns: ColumnDescription[] = [
        {
            text: "File Name",
            classes: "ellipsis",
            dataField: "name",
            sort: true,
            style: { width: "50%" },
            headerStyle: { width: "50%",paddingLeft:"0px" },
            formatter: (value, row) => (
                <div
                    className="file-download-link"
                    title={value}
                    onClick={() => downloadSingleFile(row)}
                >
                    <AttachmentIcon />
                    <span className="file-name">{value}</span>
                </div>
            ),
        },
        {
            text: "Size",
            dataField: "size",
            style: { width: "20%" },
            headerStyle: { width: "20%" },
            formatter: (value, row) => <span>{formatBytes(value)}</span>,
        },
        {
            text: "Action",
            dataField: "size",
            style: { width: "20%" },
            headerStyle: { width: "20%" },
            formatter: (value, row) => <span

                onClick={() => {
                    if (state.allFileExist) { downloadSingleFile(row) }
                }}
                className={`table-inline-icon-container ${state.allFileExist ? "" : "disabled"}`}
            >{<DownloadIcon
                width={20}
                fillColor={state.allFileExist ? "#05386b" : "#929292"}
                height={20} />
                }
                <span className="table-icon-inline-text">{DownloadPageConstants.DOWNLOAD_BUTTON_TEXT}</span>
            </span>,
        },
    ];

    const downloadAllFiles = () => {
        logger.trackEvent(
            logger.buildEvent(`${pageTitle}: download all files clicked`, {
                page: pageTitle,
                clientId: clientId,
                files: JSON.stringify(state.files),
            })
        );
        dispatch(downloadFiles(clientId, state.files, handleSuccess, handleError));
    };

    const downloadSingleFile = (row: any) => {
        logger.trackEvent(
            logger.buildEvent(`${pageTitle}: download single file clicked`, {
                page: pageTitle,
                clientId: clientId,
                fileGuid: row.fileGuid,
            })
        );
        dispatch(
            downloadFile(clientId, row.fileId, row.fileGuid, row.name, handleSuccess, handleError)
        );
    };

    const handleSuccess = (uri: string) => {
        logger.trackEvent(
            logger.buildEvent(
                `${pageTitle}: download file completed successfully`,
                {
                    page: pageTitle,
                    clientId: clientId,
                    fileUri: uri,
                }
            )
        );
        handleDownload(uri);
    };

    const handleError = () => {
        logger.trackEvent(
            logger.buildEvent(`${pageTitle}: download file failed`, {
                page: pageTitle,
                clientId: clientId,
            })
        );
        AppNotifier.Error(DownloadPageConstants.DOWNLOAD_ERROR_MESSAGE)
        setState((state) => {
            return {
                ...state,
                isFileListLoading: false
            }
        });
    };

    const onMessageIconClick = () => {
        if (state.message.length > 0) {
            setState((state) => {
                return { ...state, showMessageModal: true }
            })
        }
    }
    return (
        <>
            <div className="file-download-container">
                <div>
                    <div className="row">
                        <div className="title-container">
                            <span className="files-text">
                                {DownloadPageConstants.FILES_READY_TEXT}
                                <span className="files-count"> ({state.files.length}) </span>
                            </span>
                            <span
                                className={`message-icon-container ${state.message.length > 0 ? "" : "disabled"}`}
                                title={DownloadPageConstants.MESSAGE_ICON_TOOLTIP}
                                onClick={onMessageIconClick}>
                                <ChatMessageIcon />
                            </span>
                        </div>
                    </div>
                    <Alert
                        show={!state.allFileExist}
                        variant="warning"
                    >
                        <i className="fas fa-exclamation-triangle margin-right-10-px" />
                        {DownloadPageConstants.FilesNotAvailableForDownload.replace("<sendername>", state.senderName)}
                    </Alert>
                    <LoadingOverlay>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 fixed-height-wrapper">
                                {state.files.length ? (
                                    <BootstrapTable
                                        bordered={false}
                                        classes="primary-table file-download-table"
                                        keyField="fileGuid"
                                        columns={columns}
                                        data={state.files}
                                    />
                                ) : (
                                    <div className="no-files-container">
                                        <span className="file-list-placeholder">
                                            {PlaceholderText.NO_FILES}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Loader loading={state?.isFileListLoading} />
                    </LoadingOverlay>
                    <div className="download-btn-div">
                        <Button
                            className="button-primary-blue-2"
                            onClick={() => { if (state.allFileExist) { downloadAllFiles(); } }}
                            disabled={!(state.files.length && state.allFileExist)}
                        >
                            {DownloadPageConstants.DOWNLOAD_ALL_BUTTON_TEXT}
                        </Button>
                    </div>
                </div>
                {state.showMessageModal && <MessageModal
                    show={state?.showMessageModal}
                    onHide={() => { setState({ ...state, showMessageModal: false }) }}
                    onSubmit={() => { setState({ ...state, showMessageModal: false }) }}
                    showAlert={true}
                    title={DownloadPageConstants.MESSAGE_MODAL_TITLE}
                    confirmButtonName={DownloadPageConstants.MESSAGE_MODAL_CONFIRM_BUTTON}
                    alertMessage={DownloadPageConstants.MESSAGE_MODAL_ALERT_MESSAGE}
                >

                    <div>
                        <Markup
                            className="sender-message"
                            content={state.message || PlaceholderText.NO_MESSAGE}
                        />
                    </div>
                </MessageModal>}
            </div>
        </>
    );
};

export default FileDownload;
