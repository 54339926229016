import { Action, Reducer } from "redux";
import { IHeaderInfo, initialHeaderInfo } from "../../core/viewModels/HeaderInfo";
import { initialSentMessage, ISentMessageModel } from "../../core/viewModels/SentMessageModel";
import {
    initialSentMessageRecipient,
    ISentMessageRecipientModel
} from "../../core/viewModels/SentMessageRecipientModel";
import { DispatchActions } from "../actions/sendLinkActions";
import { actionTypes } from "../types/sendLinkTypes";

export interface ISendLinkState {
    messageInfo: ISentMessageModel;
    recipientInfo: ISentMessageRecipientModel;
    headerInfo: IHeaderInfo;
    isLoading: boolean;
    isRequestScreenCompleted: boolean;
}

const initialState: ISendLinkState = {
    isLoading: false,
    messageInfo: initialSentMessage,
    recipientInfo: initialSentMessageRecipient,
    headerInfo: initialHeaderInfo,
    isRequestScreenCompleted: false
}

export const reducer: Reducer<ISendLinkState> = (prevState: ISendLinkState = initialState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    const newState = Object.assign({}, prevState);
    switch (action.type) {
        case actionTypes.REQUEST_RECIPIENT_INFO: newState.isLoading = true;
            break;
        case actionTypes.RECEIVED_RECIPIENT_INFO: newState.messageInfo = (action as any).data.message;
            newState.recipientInfo = (action as any).data.recipient;
            newState.isLoading = false;
            newState.isRequestScreenCompleted = true;
            break;
        case actionTypes.RECEIVED_HEADER_INFO: newState.headerInfo = (action as any).data;
            break;
    }
    return newState;
}