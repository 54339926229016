import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, InputGroup, FormLabel } from "react-bootstrap";
import { Input } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { ActionMeta } from "react-select";
import {
  getFileExtension,
  getRestrictedFileMessage,
  isMaxUploadSizeLimitExceeded,
  isValidEmailAddress,
  validateAllFilesUploaded,
  validateFileSize,
  validateFileType,
} from "../helper/Validations";
import { IFileModel, IFileUploadModel } from "../core/viewModels/FileModel";
import AttachmentList from "./Attachments/AttachmentList";
import { AccessCodeModal } from "./AccessCodeModal";
import { AppNotifier } from "../helper/AppNotifier";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import {
  DropOffConstants,
  DropoffSenderForm,
  LogEventConstants,
} from "../helper/Constants";
import {
  fetchDropOffPageInfo,
  fetchUploadAttachmentSasLink,
  removeAttachment,
  sendDropOffMessage,
  validateDropOffLink,
  fetchDropOffSettings,
  fetchFolderDropOffRecipient,
  ValidateDropOffRecipient,
} from "../store/actions/dropOffActions";
import { useDispatch, useSelector } from "react-redux";
import {
  IDropOffPageInfo,
  IDropOffRecipient,
  IDropOffSettings,
  initialSentMessageRecipientModel,
  ISentMessageRecipientModel,
} from "../core/viewModels/DropffModel";
import { uploadFile } from "../core/common/commonUpload";
import { DropOffType, RecipientType } from "../core/common/enums";
import { ApplicationState } from "../store";
import TinyMCEComponent from "./tinymce/TinyMCE";
import { CustomModal } from "./CustomModal";
import { logger } from "../App";
import AttachmentUploader from "./AttachmentUploader";
import { getFileName, getUserIdFromEmail } from "../helper/HelperFunctions";
import { FloatingLabel } from "react-bootstrap";
import { CloseXButton, FileDropIcon } from "../common/icons/svgIcons";

export interface IFormDataState {
  senderEmail: string;
  senderFirstName: string;
  senderLastName: string;
  recipients: ISentMessageRecipientModel[];
  subject: string;
  message: string;
  attachments: IFileModel[];
  isFormDirty: boolean;
  rememberMe: boolean;
  personalDropOffUserId: number;
}

const initialFormDataState: IFormDataState = {
  senderEmail: "",
  senderFirstName: "",
  senderLastName: "",
  recipients: [],
  subject: "",
  message: "",
  attachments: [],
  isFormDirty: false,
  rememberMe: false,
  personalDropOffUserId: 0,
};

const createOption = (value: string, label: string) => ({
  label,
  value,
});

const ShareFileCard: React.FC = (props: any) => {
  const [formData, setFormData] =
    useState<IFormDataState>(initialFormDataState);
  const [confirmSend, setConfirmSend] = useState({
    show: false,
    sendAnyway: false,
    message: "",
  });
  const clientId = props.match.params.clientId;
  const dispatch = useDispatch();
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recipients, setRecipients] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<IFileUploadModel[]>([]);
  const [recipientOptions, setRecipientOptions] = useState<any[]>([]);
  const [editorKey, setEditorKey] = useState("");
  const [isValidatingDropOffLink, setIsValidatingDropOffLink] = useState(true);
  const [isValidatingDropOffRecipient, setIsValidatingDropOffRecipient] = useState(false);
  const [showDragAndDrop, setShowDragAndDrop] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<number>();
  const [fromEmailErrorText, setFromEmailErrorText] = useState<string>("");
  const [fromFirstNameErrorText, setFromFirstNameErrorText] =
    useState<string>("");
  const [fromLastNameErrorText, setFromLastNameErrorText] =
    useState<string>("");

  const documentsRef = React.useRef<IFileUploadModel[]>([]);
  documentsRef.current = attachments;

  const dropOffState = useSelector(
    (state: ApplicationState) => state.dropOffState
  );

  const pageTitle = "Dropoff page";
  const isFolderDropOff = props.location.pathname.includes("FolderDropOff");
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    if ((value || value == "") && name && name != "") {
      setFormData({ ...formData, [name]: value, isFormDirty: true });
      setSelectedField(0);

      switch (name) {
        case DropOffConstants.SENDER_EMAIL:
          setFromEmailErrorText("");
          break;
        case DropOffConstants.SENDER_FIRST_NAME:
          setFromFirstNameErrorText("");
          break;
        case DropOffConstants.SENDER_LAST_NAME:
          setFromLastNameErrorText("");
          break;
        default:
          break;
      }
    }
  };

  const handleRememberMeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = (event.target as HTMLInputElement)?.checked;
    if (value) {
      setFormData({ ...formData, rememberMe: value });
      logger.trackEvent(
        logger.buildEvent(
          `${value
            ? LogEventConstants.Common.Checked
            : LogEventConstants.Common.Unchecked
          } ${pageTitle}: RememberMe updated`,
          {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: formData.recipients,
            attachments: formData.attachments,
          }
        )
      );
    }
  };

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${pageTitle} loaded`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    const dropOffType = isFolderDropOff
      ? DropOffType.FolderDropOff
      : DropOffType.DropOff;

    dispatch(
      validateDropOffLink(clientId, dropOffType, () => {
        props.history.push("/Error");
      })
    );

    dispatch(
      fetchDropOffPageInfo(
        clientId,
        dropOffType,
        (dropOffPageInfo: IDropOffPageInfo) => {
          handleDropOffPageInfo(dropOffPageInfo, dropOffType);
        }
      )
    );
  }, []);

  useEffect(() => {
    const dropArea = document.getElementsByClassName("body-container")[0];

    if (dropArea) {
      dropArea.addEventListener("dragenter", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(true);
      });

      dropArea.addEventListener("dragleave", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(false);
      });

      dropArea.addEventListener("dragover", (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowDragAndDrop(true);
      });

      dropArea.addEventListener("drop", (e: any) => {
        if ((e?.target?.className).includes("drag-drop-container")) {
          setShowDragAndDrop(false);
        } else {
          e.preventDefault();
          setShowDragAndDrop(true);
        }
      });
    }
  }, []);

 
  const handleDropOffPageInfo = (
    dropOffPageInfo: IDropOffPageInfo,
    dropOffType: DropOffType
  ) => {
    if (dropOffType == DropOffType.DropOff) {
      dispatch(
        fetchDropOffSettings(
          clientId,
          dropOffType,
          (settingsResponse: IDropOffSettings) => {
            handleDropOffRecipients(dropOffPageInfo, settingsResponse);
          }
        )
      );
    } else {
      dispatch(
        fetchFolderDropOffRecipient(
          clientId,
          (recepientResponse: IDropOffRecipient) => {
            handleFolderDropOffRecipients(dropOffPageInfo, recepientResponse);
          }
        )
      );
    }
  };

  useEffect(() => {
    attachments.length > 0 && onUploadFile(attachments);
  }, [documentsRef.current.length]);

  useEffect(() => {
    confirmSend.sendAnyway && handleSendFile();
  }, [confirmSend]);

  const handleDropOffRecipients = (
    pageInfoResponse: IDropOffPageInfo,
    settingsResponse: IDropOffSettings
  ) => {
    setIsValidatingDropOffLink(false);
    let personalDropOffRecipient;
    if (
      settingsResponse.isEmailAddressLookupAllowed &&
      pageInfoResponse.personalDropOffUserId === 0
    ) {
      const options = settingsResponse.dropOffRecipients.map(
        (item: IDropOffRecipient) =>
          createOption(
            `${item.emailAddress}`,
            item.firstName + " " + item.lastName
          )
      );

      const defaultRecipient = settingsResponse.dropOffRecipients.find(
        (x) => x.isDefault
      );
      if (defaultRecipient) setDefaultDropOffRecipient(defaultRecipient);
      setRecipientOptions(options);
    } else {
      if (settingsResponse.isPersonalDropOffLinkToDisplay) {
        personalDropOffRecipient = settingsResponse.dropOffRecipients.find(
          (x) => x.userId === pageInfoResponse.personalDropOffUserId
        );
        if (personalDropOffRecipient) {
          setDefaultDropOffRecipient(personalDropOffRecipient);
        }
      }
    }

    if (
      pageInfoResponse.personalDropOffUserId !== 0 &&
      (!settingsResponse.isPersonalDropOffLinkToDisplay ||
        !personalDropOffRecipient)
    ) {
      props.history.push("/Error");
    }

    setFormData({
      ...formData,
      senderFirstName: localStorage.getItem("senderFirstName") ?? "",
      senderLastName: localStorage.getItem("senderLastName") ?? "",
      senderEmail: localStorage.getItem("senderEmail") ?? "",
      rememberMe: localStorage.getItem("senderEmail") ? true : false,
      personalDropOffUserId: pageInfoResponse.personalDropOffUserId,
    });
  };

  const handleFolderDropOffRecipients = (
    pageInfoResponse: IDropOffPageInfo,
    dropOffRecipients: IDropOffRecipient
  ) => {
    setIsValidatingDropOffLink(false);
    setDefaultDropOffRecipient(dropOffRecipients);

    setFormData({
      ...formData,
      senderFirstName: localStorage.getItem("senderFirstName") ?? "",
      senderLastName: localStorage.getItem("senderLastName") ?? "",
      senderEmail: localStorage.getItem("senderEmail") ?? "",
      rememberMe: localStorage.getItem("senderEmail") ? true : false,
      personalDropOffUserId: pageInfoResponse.personalDropOffUserId,
    });
  };

  const handleSendFile = () => {
    if (validateMessage()) {
      const sentRecepients: ISentMessageRecipientModel[] = [];

      recipients.map((item: any) => {
        sentRecepients.push({
          ...initialSentMessageRecipientModel,
          firstName: item.label, //item.firstName,
          lastName: item.label,
          email: item.value, //TO DO: need to check on this
          recipientType: RecipientType.ToRecipient,
          recipientId: item.userId || undefined,
        });
      });

      const tempAttachments: IFileModel[] = attachments.map((item) => ({
        fileId: item.fileId,
        fileGuid: item.guid,
        isUploadComplete: item.isUploaded,
        name: getFileName(item.file.name),
        size: item.fileSize ?? item.file.size,
        uploadProgress: item.uploadProgress * 100,
      }));

      setSenderDetailsLocal();
      setFormData({
        ...formData,
        recipients: sentRecepients,
        attachments: tempAttachments,
      });

      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: send message clicked`, {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: sentRecepients,
          attachments: tempAttachments,
        })
      );

      setShowAccessCodeModal(true);
    }
  };

  const validateSenderDetails = () => {
    if (
      !formData.senderEmail &&
      !formData.senderFirstName &&
      !formData.senderLastName
    ) {
      AppNotifier.Warning(DropOffConstants.WARNING_SENDER_DETAILS);
      return false;
    }
    if (!formData.senderEmail) {
      setFromEmailErrorText(DropOffConstants.EMAIL_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.EMAIL);
      return false;
    }
    if (!isValidEmailAddress(formData.senderEmail)) {
      setFromEmailErrorText(DropOffConstants.INVALID_EMAIL_FORMAT);
      setSelectedField(DropoffSenderForm.EMAIL);
      return false;
    }
    if (!formData.senderFirstName) {
      setFromFirstNameErrorText(DropOffConstants.FIRST_NAME_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.FIRST_NAME);
      return false;
    }
    if (!formData.senderLastName) {
      setFromLastNameErrorText(DropOffConstants.LAST_NAME_EMPTY_WARNING);
      setSelectedField(DropoffSenderForm.LAST_NAME);
      return false;
    }
    return true;
  };

  const validateMessage = () => {
    if (validateSenderDetails()) {
      if (recipients?.length === 0) {
        AppNotifier.Warning(DropOffConstants.RECIPIENTS_WARNING);
        return;
      }

      if (!formData.subject) {
        AppNotifier.Warning(DropOffConstants.SUBJECT_EMPTY_WARNING);
        return;
      }

      if (!formData.message) {
        AppNotifier.Warning(DropOffConstants.MESSAGE_EMPTY_WARNING);
        return;
      }

      if (attachments?.length === 0 && !confirmSend.sendAnyway) {
        setConfirmSend({
          show: true,
          sendAnyway: false,
          message: DropOffConstants.WITHOUT_ATTACHMENT_WARNING,
        });
        return;
      }

      if (!validateAllFilesUploaded(attachments)) {
        AppNotifier.Warning(
          DropOffConstants.ATTACHMENT_UPLOAD_INCOMPLETE_WARNING
        );
        return;
      }
      return true;
    }
  };

  const handleSendMessage = (dropOffType: DropOffType) => {
    if (formData.recipients.length > 0) {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: sending message`, {
          count: 1,
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: formData.recipients,
          attachments: formData.attachments,
          dropOffType: dropOffType,
        })
      );
      dispatch(
        sendDropOffMessage(
          formData,
          clientId,
          dropOffType,
          dropOffType === DropOffType.FolderDropOff,
          handleSuccess,
          handleError
        )
      );
    }
  };

  const setSenderDetailsLocal = () => {
    if (formData.rememberMe) {
      localStorage.setItem("senderFirstName", formData.senderFirstName);
      localStorage.setItem("senderLastName", formData.senderLastName);
      localStorage.setItem("senderEmail", formData.senderEmail);
    } else {
      localStorage.removeItem("senderFirstName");
      localStorage.removeItem("senderLastName");
      localStorage.removeItem("senderEmail");
    }
  };

  const handleSuccess = (clientResponse: any) => {
    if (clientResponse.isSuccess) {
      AppNotifier.Success(DropOffConstants.DROP_OFF_SUCCESS_MESSAGE);
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: message sent successfully`, {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
          recipients: formData.recipients,
          attachments: formData.attachments,
        })
      );
      if (dropOffState.dropOffPageInfo.personalDropOffUserId !== 0) {
        const selectedUser =
          dropOffState.dropOffSettings.dropOffRecipients.find(
            (x) =>
              x.userId === dropOffState.dropOffPageInfo.personalDropOffUserId
          );
        if (selectedUser) setDefaultDropOffRecipient(selectedUser);
      } else if (dropOffState.dropOffSettings.isEmailAddressLookupAllowed) {
        const defaultRecipient =
          dropOffState.dropOffSettings.dropOffRecipients.find(
            (x) => x.isDefault
          );
        if (defaultRecipient) setDefaultDropOffRecipient(defaultRecipient);
      } else setRecipients([]);
      setAttachments([]);
      setFormData({
        ...initialFormDataState,
        senderFirstName: localStorage.getItem("senderFirstName") ?? "",
        senderLastName: localStorage.getItem("senderLastName") ?? "",
        senderEmail: localStorage.getItem("senderEmail") ?? "",
        rememberMe: localStorage.getItem("senderEmail") ? true : false,
        personalDropOffUserId:
          dropOffState.dropOffPageInfo.personalDropOffUserId,
      });
      const key = (+new Date()).toString();
      setEditorKey(key);
    } else {
      if (clientResponse.errorCode === DropOffConstants.ERROR_DROP_OFF) {
        AppNotifier.Error(clientResponse.errorDescription);
      } else {
        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: message sending failed`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: formData.recipients,
            attachments: formData.attachments,
          })
        );
        props.history.push("/Error");
      }
    }
  };

  const handleError = () => {
    AppNotifier.Error(DropOffConstants.MESSAGE_SEND_FAILED);
    setAttachments([]);
  };

  const setDefaultDropOffRecipient = (selectedUser: IDropOffRecipient) => {
    const option = createOption(
      `${selectedUser.emailAddress}`,
      selectedUser.firstName + " " + selectedUser.lastName
    );
    setRecipients([option]);
  };

  const handleOnDropFiles = (e: any, callback?: any) => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles: IFileUploadModel[] = [...attachments];
    const files: File[] = e.target.files || e.dataTransfer.files;
    const { restrictedFileTypes } = dropOffState.dropOffSettings;
    let isFileInValid = true;
    let totalSize = 0;
    Array.from(files).forEach((item: File) => {
      totalSize += item.size;
    });

    attachments.forEach((attachment) => {
      totalSize += attachment.file.size;
    });

    if (isMaxUploadSizeLimitExceeded(totalSize)) {
      Array.from(files).forEach((item: File) => {
        const fileType = getFileExtension(item.name);
        if (validateFileSize(item)) {
          if (
            validateFileType(fileType, restrictedFileTypes) ||
            isFolderDropOff
          ) {
            newFiles.push({
              file: item,
              uploadProgress: 0,
              guid: uuidv4(),
              isUploaded: false,
            });
          } else {
            isFileInValid = false;
          }
        }
      });
    }
    !isFolderDropOff &&
      !isFileInValid &&
      AppNotifier.Warning(getRestrictedFileMessage(restrictedFileTypes));
    setAttachments(newFiles);
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const onBrowseFiles = (e: any, callback?: any) => {
    handleOnDropFiles(e, callback);
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: files browsed`, {
        page: pageTitle,
        clientId: clientId,
        senderEmail: formData.senderEmail,
        personalDropOffUserId: formData.personalDropOffUserId,
        recipients: formData.recipients,
      })
    );
  };

  const onUploadFile = (documents: IFileUploadModel[]) => {
    for (let i = 0; i < documents.length; i++) {
      if (!documents[i].isUploaded) {
        const fileName = getFileName(documents[i].file.name);
        dispatch(
          fetchUploadAttachmentSasLink(
            documents[i].guid,
            clientId,
            isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff,
            (data: any) => {
              uploadFile(
                documents[i].file,
                data,
                fileName,
                uploadProgressCallback,
                uploadCompleteCallback
              );
            }
          )
        );

        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: upload file started`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: JSON.stringify(formData.recipients),
            fileInfo: {
              name: documents[i].file.name,
              type: documents[i].file.type,
              size: documents[i].file.size,
              uploadProgress: documents[i].uploadProgress,
              guid: documents[i].guid,
              isUploaded: documents[i].isUploaded,
            },
          })
        );
      }
    }
  };

  const uploadProgressCallback = (percent: number, fileToUpload: any) => {
    const updatedState = documentsRef.current.map((item) => {
      if (item.guid === fileToUpload.fileGUID) {
        item.uploadProgress = percent / 100;
        if (percent == 100) {
          item.isUploaded = true;
        }
      }
      return item;
    });
    setAttachments(updatedState);
  };

  const uploadCompleteCallback = (fileToUpload: any, errorMessage?: string) => {
    if (fileToUpload.uploadFailed) {
      const validAttachments = documentsRef.current.filter(
        (file) => file.guid != fileToUpload.fileGUID
      );
      if (
        errorMessage &&
        validAttachments.length < documentsRef.current.length
      ) {
        AppNotifier.Warning(errorMessage);
      }
      setAttachments(validAttachments);
      return;
    }

    const updatedState = attachments.map((item) => {
      if (item.guid === fileToUpload.fileGUID && !fileToUpload.uploadFailed) {
        item.uploadProgress = 1;
        item.isUploaded = true;

        logger.trackEvent(
          logger.buildEvent(`${pageTitle}: upload file completed`, {
            page: pageTitle,
            clientId: clientId,
            senderEmail: formData.senderEmail,
            personalDropOffUserId: formData.personalDropOffUserId,
            recipients: JSON.stringify(formData.recipients),
            fileInfo: {
              name: item.file.name,
              type: item.file.type,
              size: item.file.size,
              uploadProgress: item.uploadProgress,
              guid: item.guid,
              isUploaded: item.isUploaded,
            },
          })
        );
      }
      return item;
    });
    setAttachments(updatedState);
  };

  const handleOnRemoveAttachment = (guid: string) => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: remove attachment clicked`, {
        page: pageTitle,
        clientId: clientId,
        senderEmail: formData.senderEmail,
        personalDropOffUserId: formData.personalDropOffUserId,
        recipients: JSON.stringify(formData.recipients),
        fileGuid: guid,
      })
    );

    dispatch(
      removeAttachment(
        guid,
        clientId,
        isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff,
        () => {
          let updatedFiles: IFileUploadModel[] = [...attachments];
          updatedFiles = updatedFiles.filter((x) => x.guid !== guid);
          setAttachments(updatedFiles);

          logger.trackEvent(
            logger.buildEvent(`${pageTitle}: remove attachment succeeded`, {
              page: pageTitle,
              clientId: clientId,
              senderEmail: formData.senderEmail,
              personalDropOffUserId: formData.personalDropOffUserId,
              recipients: JSON.stringify(formData.recipients),
              fileGuid: guid,
              attachments: updatedFiles,
            })
          );
        }
      )
    );
  };

  const handleToChange = (newValue: any, data: ActionMeta<any>) => {
    switch (data.action) {
      case "select-option":
      case "create-option":
        let enteredEmail = data.option.value;
        if (enteredEmail && isValidEmailAddress(enteredEmail)) {
          if (!dropOffState.dropOffSettings.isEmailAddressLookupAllowed) {
            validateRecipientOnAddressLookupOff(enteredEmail);
          } else if (checkIfEmailExistsInDropOffRecipients(enteredEmail)) {
            const isLabelHasEmailId = isValidEmailAddress(data.option.label);
            const userInfo = getUserInfo(
              isLabelHasEmailId ? data.option.label : data.option.value
            )[0];
            const newRecipient: IDropOffRecipient = {
              firstName:
                userInfo?.firstName ?? data.option.label.split(" ")[0] ?? "",
              lastName:
                userInfo?.lastName ?? data.option.label.split(" ")[1] ?? "",
              emailAddress: enteredEmail,
              userId: getUserIdFromEmail(
                dropOffState.dropOffSettings.dropOffRecipients,
                enteredEmail
              ),
              isDefault: false,
            };
            const option = createOption(
              newRecipient.emailAddress,
              userInfo?.firstName + " " + userInfo?.lastName
            );
            setRecipients([...recipients, option]);
          } else {
            AppNotifier.Warning(DropOffConstants.NO_USER_FOUND_MESSAGE);
          }
        } else {
          AppNotifier.Warning(DropOffConstants.INVALID_EMAIL_FORMAT);
        }
        break;
      case "remove-value":
      case "pop-value":
        const emailAddress = data.removedValue.value;
        let filteredRecipients = recipients.filter(
          (x) => x.value !== emailAddress
        );
        setRecipients(filteredRecipients);
        break;
    }
  };

  const checkIfEmailExistsInDropOffRecipients = (email: string) => {
    return dropOffState.dropOffSettings.dropOffRecipients.some(
      (x) => x.emailAddress === email
    );
  };

  const validateRecipientOnAddressLookupOff = (email: string) => {
    setIsValidatingDropOffRecipient(true);
    dispatch(ValidateDropOffRecipient(clientId, DropOffType.DropOff, email, handleRecipientValidation));
  };

  const handleRecipientValidation = (
    response: boolean,
    enteredEmail: string
  ) => {
    if (response) {
      const newRecipient: IDropOffRecipient = {
        firstName: "",
        lastName: "",
        emailAddress: enteredEmail,
        userId: 0,
        isDefault: false,
      };
      const option = createOption(
        `${newRecipient.emailAddress}`,
        newRecipient.emailAddress
      );
      setRecipients([...recipients, option]);
      setIsValidatingDropOffRecipient(false);
    } else {
      AppNotifier.Warning(DropOffConstants.NO_USER_FOUND_MESSAGE);
      setIsValidatingDropOffRecipient(false);
    }
  };

  const getUserInfo = (email: string) => {
    return dropOffState?.dropOffSettings?.dropOffRecipients.filter(
      (user) => user.emailAddress === email
    );
  };

  const handleOnHideConfirmSend = () => {
    logger.trackEvent(
      logger.buildEvent(
        `${LogEventConstants.Common.Close} ${LogEventConstants.Dropoff.SendWithoutAttachmentConfirmationModal.Title}`,
        {
          page: pageTitle,
          clientId: clientId,
          senderEmail: formData.senderEmail,
          personalDropOffUserId: formData.personalDropOffUserId,
        }
      )
    );
    setConfirmSend({ show: false, sendAnyway: false, message: "" });
  };

  const handleMessageBodyChange = (value: any) => {
    setFormData({ ...formData, message: value });
  };

  const getLoaderMessage = () => {
    if (dropOffState.isSending) {
      return DropOffConstants.SENDING_MESSAGE_LOADER_TEXT;
    } else {
      return DropOffConstants.LOADING_LOADER_TEXT;
    }
  };
  return (
    <LoadingOverlay className="share-file-card-wrapper">
      <div className="sharefilecard-container">
        <div className="from-container">
          <h5>{DropOffConstants.FROM_TITLE}</h5>
          <div className="to-form-container">
            <div className="input-container">
              <FormLabel>{DropOffConstants.LABEL_EMAIL}</FormLabel>
              <Form.Control
                type="email"
                name="senderEmail"
                className={`form-input-text primary-input ${fromEmailErrorText?.length > 0 &&
                    formData.senderEmail.length >= 0
                    ? "error"
                    : ""
                  }`}
                placeholder={DropOffConstants.PLACEHOLDER_EMAIL}
                onChange={handleOnChange}
                value={formData.senderEmail}
              ></Form.Control>
              {fromEmailErrorText && (
                <div className="invalid-feedback">{fromEmailErrorText}</div>
              )}
            </div>
            <div className="input-container">
              <FormLabel>{DropOffConstants.LABEL_FIRST_NAME}</FormLabel>
              <Form.Control
                type="text"
                name="senderFirstName"
                className={`form-input-text ${fromFirstNameErrorText?.length > 0 &&
                    formData.senderFirstName.length >= 0
                    ? "error"
                    : ""
                  }`}
                placeholder={DropOffConstants.PLACEHOLDER_FIRST_NAME}
                maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                onChange={handleOnChange}
                value={formData.senderFirstName}
              ></Form.Control>
              {fromFirstNameErrorText && (
                <div className="invalid-feedback">{fromFirstNameErrorText}</div>
              )}
            </div>
            <div className="input-container">
              <FormLabel>{DropOffConstants.LABEL_LAST_NAME}</FormLabel>
              <Form.Control
                type="text"
                name="senderLastName"
                className={`form-input-text ${fromLastNameErrorText?.length > 0 &&
                    formData.senderLastName.length >= 0
                    ? "error"
                    : ""
                  }`}
                placeholder={DropOffConstants.PLACEHOLDER_LAST_NAME}
                maxLength={DropOffConstants.FIRST_NAME_MAXLENGTH}
                onChange={handleOnChange}
                value={formData.senderLastName}
              ></Form.Control>
              {fromLastNameErrorText && (
                <div className="invalid-feedback">{fromLastNameErrorText}</div>
              )}
            </div>
          </div>
          <div className="from-footer-links-container">
            <span>
              <Input
                id="remember-me"
                type="checkbox"
                name="rememberMe"
                className="primary-checkbox"
                checked={formData.rememberMe}
                onChange={handleRememberMeChange}
              ></Input>
              <span>{DropOffConstants.REMEMBER_ME}</span>
            </span>
          </div>
        </div>
        <div
          className={`to-container ${attachments?.length > 4 && recipients.length >= 5
              ? "to-container-more-files"
              : ""
            }`}
        >
          <h5>{DropOffConstants.TO_TITLE}</h5>
          <Form>
            <div className="to-top-container">
              <InputGroup>
                <div className="to-recipent-container">
                  <CreatableSelect
                    isMulti={true}
                    isClearable={false}
                    placeholder="Add Recipients"
                    classNamePrefix="recipient"
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    allowCreateWhileLoading={true}
                    options={recipientOptions}
                    onChange={handleToChange}
                    isDisabled={isFolderDropOff ? true : false}
                    formatCreateLabel={(value: any) => value}
                    theme={(theme: any) => {
                      return { ...theme, borderRadius: 0 };
                    }}
                    components={{
                      DropdownIndicator: null,
                      MultiValueRemove: (props) => (
                        <span
                          className="recipient-multi-value-remove-icon"
                          onClick={props.innerProps.onClick}
                          onTouchEnd={props.innerProps.onTouchEnd}
                        >
                          <CloseXButton width={10} height={10} />
                        </span>
                      ),
                    }}
                    styles={creatableStyle}
                    value={recipients}
                    isLoading={isLoading}
                  />
                </div>
              </InputGroup>
              <InputGroup>
                <FloatingLabel controlId="floatingInput" label="Subject">
                  <Form.Control
                    placeholder="Subject"
                    name="subject"
                    maxLength={DropOffConstants.SUBJECT_MAXLENGTH}
                    onChange={handleOnChange}
                    value={formData.subject}
                  ></Form.Control>
                </FloatingLabel>
              </InputGroup>
              <div className="body-container">
                <TinyMCEComponent
                  messageBody={formData.message}
                  changeStateTinymceBody={(e) => handleMessageBodyChange(e)}
                  readOnly={0}
                  key={editorKey}
                  onDragOver={() => setShowDragAndDrop(true)}
                />
                <div
                  className={`drag-drop-container ${!showDragAndDrop ? "hide" : ""
                    }`}
                  onDrop={(e) => {
                    logger.trackEvent(
                      logger.buildEvent(`${pageTitle}: file(s) dropped`, {
                        page: pageTitle,
                        clientId: clientId,
                        senderEmail: formData.senderEmail,
                        personalDropOffUserId: formData.personalDropOffUserId,
                        recipients: formData.recipients,
                      })
                    );
                    handleOnDropFiles(e);
                  }}
                >
                  <FileDropIcon />
                  <span>{DropOffConstants.DRAG_AND_DROP_TEXT}</span>
                  <span className="drag-drop-helper-text">
                    {DropOffConstants.DRAG_AND_DROP_SUB_TEXT}
                  </span>
                </div>
              </div>
            </div>
            <div className="droppoff__attachments">
              <AttachmentList
                attachments={attachments}
                onRemoveAttachment={handleOnRemoveAttachment}
              />
            </div>
            <div className="to-bottom-container">
              <AttachmentUploader onDropFiles={onBrowseFiles} />
              <button
                className="button-primary-blue-2"
                onClick={(e) => {
                  e.preventDefault();
                  handleSendFile();
                }}
              >
                <i className="fas fa-paper-plane"></i>
                {DropOffConstants.SEND_TEXT}
              </button>
            </div>
          </Form>
        </div>
        {showAccessCodeModal && (
          <AccessCodeModal
            showState={showAccessCodeModal}
            dropOffType={
              isFolderDropOff ? DropOffType.FolderDropOff : DropOffType.DropOff
            }
            sender={() => {
              return {
                emailAddress: formData.senderEmail,
                firstName: formData.senderFirstName,
                lastName: formData.senderLastName,
                userId: 0,
                isDefault: false,
              };
            }}
            clientId={clientId}
            onHide={() => {
              setShowAccessCodeModal(!showAccessCodeModal);
              logger.trackEvent(
                logger.buildEvent(
                  `${LogEventConstants.Common.Close}  ${LogEventConstants.Dropoff.AccessCodeModal.Title}`,
                  {
                    count: 1,
                    page: pageTitle,
                    clientId: props.clientId,
                    senderEmail: formData.senderEmail,
                    personalDropOffUserId: formData.personalDropOffUserId,
                  }
                )
              );
            }}
            onSendMessage={(dropOffType) => handleSendMessage(dropOffType)}
          />
        )}

        <CustomModal
          show={confirmSend.show}
          onSubmit={() => {
            logger.trackEvent(
              logger.buildEvent(
                `${LogEventConstants.Dropoff.SendWithoutAttachmentConfirmationModal.Title}: submit clicked`,
                {
                  page: pageTitle,
                  clientId: clientId,
                  senderEmail: formData.senderEmail,
                  personalDropOffUserId: formData.personalDropOffUserId,
                }
              )
            );
            setConfirmSend({ show: false, sendAnyway: true, message: "" });
          }}
          title="Send Message"
          className="primary-modal send-without-attachment-confirmation-modal"
          confirmButtonName="Send"
          cancelButtonName="Cancel"
          onHide={handleOnHideConfirmSend}
          size="md"
        >
          {DropOffConstants.WITHOUT_ATTACHMENT_WARNING}
        </CustomModal>
      </div>
      <Loader
        loading={dropOffState.isSending || isValidatingDropOffLink || isValidatingDropOffRecipient}
        text={getLoaderMessage()}
      />
    </LoadingOverlay>
  );
};

export default ShareFileCard;

const creatableStyle = {
  control: (base: any) => ({
    ...base,
    border: "none",
    borderBottom: "solid 1px #ced4da",
    minHeight: "32px",
    fontSize: "14px",
    boxShadow: "none",
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: "13px",
  }),
};
