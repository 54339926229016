import * as  React from "react";

export interface SvgIconProps {
  width?: number;
  height?: number;
  className?: string;
  onClick?: any;
  primaryColor?: string;
  secondaryColor?: string;
  fillColor?: string;
}

export const SvgIconContactHeader: React.FC<SvgIconProps> = (props) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 24} className={props.className} onClick={props.onClick} viewBox="0 0 24 24">
    <path fill={props.fillColor || "#FFFFFF"} d="M12.053,1.448c0,0,4.402,0.202,4.402,4.552S10.643,10.35,12,10.35c1.357,0-4.35,0-4.35-4.35 S12.053,1.448,12.053,1.448z M12.15,15.15c4.966,0,10.2,3.285,10.2,4.725v2.475H1.95v-2.475C1.95,18.436,7.184,15.15,12.15,15.15z M6,6c0,3.315,2.685,6,6,6c3.314,0,6-2.685,6-6s-2.686-6-6-6C8.685,0,6,2.685,6,6z M12,13.5c-4.005,0-12,2.01-12,6V24h24v-4.5 C24,15.51,16.005,13.5,12,13.5z" />
  </svg>;
}

export const EmailEnvelopeIcon: React.FC<SvgIconProps> = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? "80"}
    height={props.height ?? "80"}
    fill="none"
    viewBox="0 0 80 80"
  >
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M68.904 56.717a1.837 1.837 0 00-2.598 0l-7.07 7.072-2.518-2.519a1.836 1.836 0 00-2.598 2.595l3.82 3.819a1.84 1.84 0 002.597 0l8.367-8.37a1.841 1.841 0 000-2.597z"
    ></path>
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M79.31 62.19A17.799 17.799 0 1061.51 80a17.818 17.818 0 0017.8-17.81zm-31.927 0a14.13 14.13 0 0114.135-14.117 14.128 14.128 0 01-.007 28.255A14.145 14.145 0 0147.384 62.19zM21.793 25.17h27.81a1.844 1.844 0 001.839-1.84c0-1.011-.828-1.839-1.84-1.839H21.794a1.844 1.844 0 00-1.839 1.84c0 1.01.828 1.838 1.84 1.838zM43.889 30.988H27.52c-1.012 0-1.84.828-1.84 1.84 0 1.011.828 1.839 1.84 1.839h16.367c1.012 0 1.84-.828 1.84-1.84 0-1.01-.828-1.838-1.84-1.838zM27.52 15.672H43.89c1.011 0 1.839-.828 1.839-1.84 0-1.011-.828-1.839-1.84-1.839H27.521c-1.012 0-1.84.828-1.84 1.84 0 1.011.828 1.839 1.84 1.839z"
    ></path>
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M40.066 70.49c0-1.012-.815-1.84-1.826-1.84H8.696c-.354 0-.722-.04-1.077-.118-.052 0-.092-.026-.131-.04-2.404-.643-3.797-3.257-3.797-7.303V35.836L30.91 48.998a9.685 9.685 0 008.552 0L66.68 35.836v4.637c0 1.011.827 1.839 1.839 1.839 1.011 0 1.84-.828 1.84-1.84V29.742a8.87 8.87 0 00-2.628-6.345l-5.228-5.229-.119-.078-2.206-2.207v-8.71a7.18 7.18 0 00-2.102-5.07A7.226 7.226 0 0053.006 0h-35.64a7.18 7.18 0 00-7.172 7.172v8.71l-2.207 2.207-.118.079-5.242 5.215A8.87 8.87 0 000 29.727V61.19c.013.604.053 1.195.131 1.8-.078.21-.13.433-.13.656 0 2.023.722 3.994 2.022 5.544A8.662 8.662 0 007.12 72.17a8.15 8.15 0 001.59.157h29.53a1.825 1.825 0 001.826-1.839zM65.13 25.996a5.256 5.256 0 011.55 3.744v2.01l-6.516 3.152V21.058l4.966 4.939zM13.872 7.172a3.501 3.501 0 013.494-3.494H52.98a3.501 3.501 0 013.494 3.494V36.69L37.833 45.7a6.067 6.067 0 01-5.347 0L13.86 36.69l.013-29.518zM3.678 29.727c0-1.405.552-2.758 1.55-3.743l4.966-4.927v13.846L3.678 31.75v-2.023z"
    ></path>
  </svg>;
}

export const SecurityShieldIcon: React.FC<SvgIconProps> = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? "70"}
    height={props.height ?? "80"}
    fill="none"
    viewBox="0 0 70 80"
  >
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M2.034 19.46A2.035 2.035 0 000 21.496v28.208A20.368 20.368 0 007.37 64.41l16.315 12.206h-.001a18.492 18.492 0 0010.985 3.382 18.482 18.482 0 0010.986-3.382l16.317-12.206a20.373 20.373 0 007.367-14.7V21.495a15.14 15.14 0 00-9.26-13.366L41.114 1.017a20.927 20.927 0 00-12.889 0L9.26 8.129a2.034 2.034 0 101.43 3.81l18.965-7.111c3.27-1 6.765-1 10.035 0l18.966 7.112a11.131 11.131 0 016.62 9.555v28.208a16.366 16.366 0 01-5.738 11.452l-16.32 12.189a15.371 15.371 0 01-17.089 0L9.806 61.154A16.365 16.365 0 014.069 49.71V21.495a2.034 2.034 0 00-2.034-2.034z"
    ></path>
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M42.862 33.933a2.035 2.035 0 00-2.878 0L32.15 41.77l-2.79-2.791a2.035 2.035 0 00-2.878 2.874l4.231 4.232a2.04 2.04 0 002.878 0l9.27-9.274h.001a2.04 2.04 0 000-2.878z"
    ></path>
    <path
      fill={props.fillColor ?? "#05386B"}
      d="M54.392 39.998A19.721 19.721 0 1034.67 59.731a19.742 19.742 0 0019.722-19.733zm-35.375 0a15.655 15.655 0 0115.661-15.642 15.654 15.654 0 01-.007 31.306 15.672 15.672 0 01-15.653-15.664z"
    ></path>
  </svg>;
}

export const FileDownloadIcon: React.FC<SvgIconProps> = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width={props.width || 24}
    height={props.height || 24}
    enableBackground="new 0 0 495 495"
    version="1.1"
    viewBox="0 0 495 495"
    xmlSpace="preserve"
  >
    <path
      fill="#2488FF"
      d="M247.5 0L163.07 0 163.07 115.57 97.742 115.57 247.5 279.669z"
    ></path>
    <path
      fill="#005ECE"
      d="M397.259 115.57L331.93 115.57 331.93 0 247.5 0 247.5 279.669z"
    ></path>
    <path fill="#FFDA44" d="M0 310H247.5V495H0z"></path>
    <path
      fill="#FFCD00"
      d="M247.5 310v185H495V310H247.5zM335 422.5c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm70 0c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20z"
    ></path>
    <circle cx="335" cy="402.5" r="20" fill="#2488FF"></circle>
    <circle cx="405" cy="402.5" r="20" fill="#2488FF"></circle>
  </svg>;
}

export const DownloadIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 16}
      height={props?.height || 17}
      fill="none"
      viewBox="0 0 16 17"
    >
      <g>
        <g fill={props?.fillColor ?? "#05386B"}>
          <path d="M.5 10.4a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 011 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z"></path>
          <path d="M7.646 12.354a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.793V2a.5.5 0 00-1 0v8.793L5.354 8.646a.5.5 0 10-.708.708l3 3z"></path>
        </g>
      </g>
    </svg>
  );
};

export const UploadIcon: React.FC<SvgIconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "16"}
    height={props.height || "16"}
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_1137_1303)">
      <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
      <g fill="#05386B" clipPath="url(#clip1_1137_1303)">
        <path d="M.5 9.899a.5.5 0 01.5.5v2.5a1 1 0 001 1h12a1 1 0 001-1v-2.5a.5.5 0 111 0v2.5a2 2 0 01-2 2H2a2 2 0 01-2-2v-2.5a.5.5 0 01.5-.5z"></path>
        <path d="M7.646 1.145a.5.5 0 01.708 0l3 3a.5.5 0 01-.708.708L8.5 2.706v8.793a.5.5 0 01-1 0V2.706L5.354 4.853a.5.5 0 11-.708-.708l3-3z"></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1137_1303">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
      <clipPath id="clip1_1137_1303">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const CheckMarkIcon: React.FC<SvgIconProps> = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width={props.width || 24}
    height={props.height || 24}
    enableBackground="new 0 0 310.277 310.277"
    version="1.1"
    viewBox="0 0 310.277 310.277"
    xmlSpace="preserve"
  >
    <path
      fill={props.fillColor ?? "#010002"}
      d="M155.139 0C69.598 0 0 69.598 0 155.139c0 85.547 69.598 155.139 155.139 155.139 85.547 0 155.139-69.592 155.139-155.139C310.277 69.598 240.686 0 155.139 0zm-10.962 196.567L90.571 142.96l8.437-8.437 45.169 45.169 81.34-81.34 8.437 8.437-89.777 89.778z"
    ></path>
  </svg>;
};


export const UploadIconColor: React.FC<SvgIconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 495 495"
    xmlSpace="preserve"
    height={props?.height || 16}
    width={props?.width || 16}
  >
    <path
      style={{
        fill: "#005ece",
      }}
      d="M331.93 279.835V164.264h65.328L247.5.165v279.67z"
    />
    <path
      style={{
        fill: "#2488ff",
      }}
      d="M163.07 279.835h84.43V.165L97.742 164.264h65.328z"
    />
    <path
      style={{
        fill: "#ffda44",
      }}
      d="M0 309.835h247.5v185H0z"
    />
    <path
      style={{
        fill: "#ffcd00",
      }}
      d="M247.5 309.835v185H495v-185H247.5zm87.5 112.5c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20zm70 0c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20-8.954 20-20 20z"
    />
    <circle
      style={{
        fill: "#2488ff",
      }}
      cx={335}
      cy={402.335}
      r={20}
    />
    <circle
      style={{
        fill: "#2488ff",
      }}
      cx={405}
      cy={402.335}
      r={20}
    />
  </svg>
);
export const DeleteIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 16}
    height={props?.height || 16}
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_1182_805)">
      <path
        fill="#05386B"
        d="M6.5.999h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zm4.5 1.5v-1a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 00-1.5 1.5v1H1.5a.5.5 0 000 1h.538l.853 10.66a2 2 0 001.994 1.84h6.23a2 2 0 001.994-1.84l.853-10.66h.538a.5.5 0 000-1H11zm1.958 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.997-.92l-.846-10.58h9.916zm-7.487 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.029a.5.5 0 01.47-.53zm5.058 0a.5.5 0 01.47.53l-.5 8.5a.5.5 0 11-.998-.06l.5-8.5a.5.5 0 01.528-.47zm-2.53 0a.5.5 0 01.5.5v8.5a.5.5 0 01-1 0v-8.5a.5.5 0 01.5-.5z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1182_805">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
  );
};

export const WarningIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      width={props?.width ?? 16}
      height={props?.height ?? 16}
      viewBox="0 0 16 15" fill={props.fillColor ?? "currentColor"} xmlns="http://www.w3.org/2000/svg" >
      <path d="M8.98201 1.56601C8.88302 1.39358 8.74028 1.25031 8.56821 1.15069C8.39614 1.05106 8.20084 0.998596 8.00201 0.998596C7.80318 0.998596 7.60787 1.05106 7.4358 1.15069C7.26373 1.25031 7.121 1.39358 7.02201 1.56601L0.165007 13.233C-0.291993 14.011 0.256007 15 1.14501 15H14.858C15.747 15 16.296 14.01 15.838 13.233L8.98201 1.56601ZM8.00001 5.00001C8.53501 5.00001 8.95401 5.46201 8.90001 5.99501L8.55001 9.50201C8.53825 9.63978 8.47521 9.76813 8.37336 9.86165C8.27152 9.95517 8.13828 10.0071 8.00001 10.0071C7.86173 10.0071 7.7285 9.95517 7.62665 9.86165C7.52481 9.76813 7.46177 9.63978 7.45001 9.50201L7.10001 5.99501C7.08744 5.86925 7.10135 5.74224 7.14085 5.62218C7.18035 5.50212 7.24456 5.39166 7.32935 5.29792C7.41414 5.20419 7.51762 5.12925 7.63313 5.07794C7.74864 5.02663 7.87361 5.00008 8.00001 5.00001ZM8.00201 11C8.26722 11 8.52158 11.1054 8.70911 11.2929C8.89665 11.4804 9.00201 11.7348 9.00201 12C9.00201 12.2652 8.89665 12.5196 8.70911 12.7071C8.52158 12.8947 8.26722 13 8.00201 13C7.73679 13 7.48244 12.8947 7.2949 12.7071C7.10736 12.5196 7.00201 12.2652 7.00201 12C7.00201 11.7348 7.10736 11.4804 7.2949 11.2929C7.48244 11.1054 7.73679 11 8.00201 11Z" />
    </svg>
  )
};

export const CheckCircle: React.FC<SvgIconProps> = props => {
  return (
    <svg
      width={props?.width ?? 16}
      height={props?.height ?? 16} viewBox="0 0 16 16" fill={props.fillColor ?? "currentColor"} xmlns="http://www.w3.org/2000/svg" >
      <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z" />
    </svg>
  )

}

export const CloseXButton: React.FC<SvgIconProps> = props => {
  return (
    <svg width={props?.width ?? 12}
      height={props?.height ?? 12} viewBox="0 0 8 8" fill={props.fillColor ?? "currentColor"} xmlns="http://www.w3.org/2000/svg" >
      <path d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.70711 4L7.35355 6.64645C7.54882 6.84171 7.54882 7.15829 7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L4 4.70711L1.35355 7.35355C1.15829 7.54882 0.841709 7.54882 0.646447 7.35355C0.451184 7.15829 0.451184 6.84171 0.646447 6.64645L3.29289 4L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z" />
    </svg>
  )
}

export const ChatMessageIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ?? 20}
      height={props?.height ?? 20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props.fillColor ?? "#05386B"}
        d="M17.5 1.25c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H5.518a2.5 2.5 0 00-1.768.732l-2.5 2.5V2.5c0-.69.56-1.25 1.25-1.25h15zM2.5 0A2.5 2.5 0 000 2.5v15.991c0 .557.673.836 1.067.442l3.567-3.567A1.25 1.25 0 015.518 15H17.5a2.5 2.5 0 002.5-2.5v-10A2.5 2.5 0 0017.5 0h-15z"
      ></path>
      <path
        fill={props.fillColor ?? "#05386B"}
        d="M3.75 4.375c0-.345.28-.625.625-.625h11.25a.625.625 0 110 1.25H4.375a.625.625 0 01-.625-.625zm0 3.125c0-.345.28-.625.625-.625h11.25a.625.625 0 110 1.25H4.375A.625.625 0 013.75 7.5zm0 3.125c0-.345.28-.625.625-.625h6.25a.625.625 0 110 1.25h-6.25a.625.625 0 01-.625-.625z"
      ></path>
    </svg>
  )
}

export const ExclamationIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props?.className}
      width={props.width || "16"}
      height={props.height || "16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_1083_1845)">
        <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
        <g clipPath="url(#clip1_1083_1845)">
          <path
            fill="#0973BA"
            d="M8 15.999a8 8 0 100-16 8 8 0 000 16zm.93-9.412l-1.002 4.705c-.07.34.03.533.305.533.193 0 .486-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.809-1.319l.739-3.468c.064-.293.005-.399-.288-.47l-.45-.081.081-.381 2.291-.287zM8 5.499a1 1 0 110-2 1 1 0 010 2z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1083_1845">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
        <clipPath id="clip1_1083_1845">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}


export const PlusIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ?? "20"}
      height={props?.height ?? "20"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props?.fillColor ?? "#05386B"}
        fillRule="evenodd"
        d="M10.002 2.5a.625.625 0 01.624.624v6.25h6.25a.625.625 0 010 1.25h-6.25v6.25a.625.625 0 01-1.25 0v-6.25h-6.25a.625.625 0 110-1.25h6.25v-6.25a.625.625 0 01.626-.625z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export const MinusIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ?? "20"}
      height={props?.height ?? "20"}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={props?.fillColor ?? "#05386B"}
        fillRule="evenodd"
        d="M17.503 10a.625.625 0 01-.625.626H3.128a.625.625 0 110-1.25h13.75a.625.625 0 01.625.625z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
export const AttachmentIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width ?? 16}
      height={props?.height ?? 16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.fillColor ?? "#05386B"}
        d="M4.5 3a2.5 2.5 0 015 0v9a1.5 1.5 0 01-3 0V5a.5.5 0 011 0v7a.5.5 0 001 0V3a1.5 1.5 0 10-3 0v9a2.5 2.5 0 005 0V5a.5.5 0 011 0v7a3.5 3.5 0 11-7 0V3z"
      ></path>
    </svg>
  )
}

export const LinkLockIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 200}
      height={props?.height || 200}
      fill="none"
      viewBox="0 0 200 200"
    >
      <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
      <path
        fill="#B4C3D3"
        d="M142.478 130.608v-2.694c0-3.768-1.511-7.382-4.201-10.046a14.418 14.418 0 00-10.145-4.162 14.414 14.414 0 00-10.144 4.162 14.139 14.139 0 00-4.202 10.046v2.694a5.443 5.443 0 00-2.876 1.916 5.354 5.354 0 00-1.125 3.247v18.503a4.4 4.4 0 001.309 3.118 4.486 4.486 0 003.149 1.294h27.778a4.489 4.489 0 003.15-1.294 4.4 4.4 0 001.308-3.118v-18.503a5.355 5.355 0 00-1.123-3.248 5.44 5.44 0 00-2.878-1.915zm-14.336-12.275a9.736 9.736 0 016.836 2.81 9.548 9.548 0 012.838 6.771v1.847a70.016 70.016 0 00-9.681-.754 70.1 70.1 0 00-9.686.754v-1.847a9.558 9.558 0 012.846-6.775 9.745 9.745 0 016.847-2.806zm-13.678 35.941v-18.503a.795.795 0 01.18-.511.81.81 0 01.466-.28 74.715 74.715 0 0113.056-1.355 74.66 74.66 0 0113.035 1.355.807.807 0 01.466.28.795.795 0 01.18.511v6.21c.012.084.029.167.05.249l.156 11.838-27.589.206z"
      ></path>
      <path
        fill="#B4C3D3"
        d="M133.719 61.033H61.586a12.75 12.75 0 00-9.018 3.76 12.865 12.865 0 00-3.742 9.06V121.6a12.864 12.864 0 003.742 9.06 12.752 12.752 0 009.018 3.761h33.63a2.32 2.32 0 001.644-.685 2.34 2.34 0 000-3.302 2.32 2.32 0 00-1.644-.684h-33.63a8.11 8.11 0 01-5.732-2.391 8.18 8.18 0 01-2.378-5.759V86.923h88.353v15.75c0 .62.245 1.214.681 1.652a2.319 2.319 0 003.288 0 2.341 2.341 0 00.681-1.652v-28.82a12.865 12.865 0 00-3.742-9.06 12.75 12.75 0 00-9.018-3.76zM53.476 82.252v-8.408a8.177 8.177 0 012.381-5.753 8.103 8.103 0 015.73-2.387h72.132a8.104 8.104 0 015.732 2.39 8.18 8.18 0 012.378 5.76v8.407l-88.353-.01z"
      ></path>
      <path
        fill="#B4C3D3"
        d="M63.622 115.186a3.85 3.85 0 004.597 3.773 3.852 3.852 0 003.023-3.023 3.848 3.848 0 00-6.493-3.471 3.849 3.849 0 00-1.127 2.721zM78.418 115.186c0 .76.243 1.504.698 2.137a4.103 4.103 0 001.86 1.417c.757.291 1.59.367 2.393.219a4.255 4.255 0 002.121-1.053 3.772 3.772 0 001.134-1.97c.16-.746.078-1.52-.236-2.223a3.923 3.923 0 00-1.525-1.726 4.37 4.37 0 00-2.302-.648 4.312 4.312 0 00-2.93 1.126c-.776.722-1.213 1.7-1.213 2.721z"
      ></path>
    </svg>
  )
}

export const ExclamationTriangleIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 18}
      height={props?.height || 18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clipPath="url(#clip0_27_52351)">
        <path
          fill="#CC4A43"
          d="M10.105 1.762a1.271 1.271 0 00-2.205 0L.186 14.887c-.515.875.102 1.988 1.102 1.988h15.427c1 0 1.618-1.114 1.103-1.988L10.105 1.762zM9 5.625c.602 0 1.073.52 1.012 1.12L9.62 10.69a.621.621 0 01-1.238 0l-.394-3.945A1.018 1.018 0 019 5.625zm.002 6.75a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_27_52351">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export const SuccessIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 17}
      height={props?.height || 17}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#669440"
        d="M8.5 17a8.5 8.5 0 100-17 8.5 8.5 0 000 17zm4.077-10.911l-3.243 5.405a1.821 1.821 0 01-2.85.35L4.606 9.966a1.214 1.214 0 011.717-1.717l1.329 1.328 2.843-4.737a1.214 1.214 0 112.082 1.249z"
      ></path>
    </svg>
  )
}

export const FileDropIcon: React.FC<SvgIconProps> = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "49"}
    height={props.height || "48"}
    fill="none"
    viewBox="0 0 49 48"
  >
    <g clipPath="url(#clip0_1869_296974)">
      <path
        fill="#05386B"
        d="M15.44 12a1.499 1.499 0 00-1.17.563L5.12 24H18.5a1.5 1.5 0 011.5 1.5 4.5 4.5 0 009 0 1.5 1.5 0 011.5-1.5h13.38l-9.15-11.436A1.5 1.5 0 0033.56 12H15.44zm29.862 15H31.85a7.5 7.5 0 01-14.7 0H3.698l.96 7.685A1.5 1.5 0 006.148 36h36.703a1.5 1.5 0 001.488-1.314l.96-7.686h.003zM11.927 10.687A4.5 4.5 0 0115.443 9h18.114a4.5 4.5 0 013.516 1.69l11.1 13.874a1.5 1.5 0 01.315 1.122l-1.17 9.372A4.5 4.5 0 0142.85 39H6.149a4.5 4.5 0 01-4.467-3.942l-1.17-9.372a1.5 1.5 0 01.318-1.122l11.1-13.875h-.003z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1869_296974">
        <path fill="#fff" d="M0 0H48V48H0z" transform="translate(.5)"></path>
      </clipPath>
    </defs>
  </svg>
  )
}