import { IDocumentRequestFile } from "../core/viewModels/DocumentRequestModel";
import { IFileUploadModel } from "../core/viewModels/FileModel";
import { AppNotifier } from "./AppNotifier";
import {
  FileConstants,
  ValidationContants,
} from "./Constants";

export function isValidEmailAddress(emailAddress: any) {
  if (emailAddress) {
    const pattern = new RegExp(
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );
    return pattern.test(emailAddress);
  }
  return false;
}

export const validateAllFilesUploaded = (attachments: IFileUploadModel[]) => {
  let isValid = true;
  if (!attachments.every(a => a.isUploaded == true)) {
    isValid = false;
  }
  return isValid;
};


function hasLowerCase(data: string) {
  const pattern = new RegExp(".*[a-z].*");
  return pattern.test(data);
}

function hasUpperCase(data: string) {
  const pattern = new RegExp(".*[A-Z].*");
  return pattern.test(data);
}

function hasNumber(data: string) {
  const pattern = new RegExp(/[\d]/i);
  return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
  const pattern = new RegExp(/[~!@#$%^&*_]/i);
  return pattern.test(data);
}

export function validateUrl(url: string) {
  return /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i.test(
    url
  );
}

export function getFileExtension(fileName: string) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function getContentType(file: File): string {
  const contentType: string = file.type.slice(0, file.type.indexOf("/"));
  if (contentType == "application") {
    return contentType + "/" + getFileExtension(file.name);
  }
  return file.type;
}


export function GetSafeFilename(fileName: string) {
  const pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
  return fileName.replace(pattern, "_");
}
export function isValidSSN(ssn: string) {
  if (ssn.replace(/-/g, "").length == 9) {
    return true;
  }
}

export function isValidEIN(ein: string) {
  if (ein.replace(/-/g, "").length === 9) {
    return true;
  }
}

export function isValidDate(date: any) {
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return date_regex.test(date);
}

export function isDigit(zip: any) {
  const date_regex = /^[0-9]{1,10}$/;
  return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
  if (text == undefined || text == null || text.trim() == "") {
    return false;
  }
  return true;
}

export function isValidatePTIN(ptin: string) {
  const ptin_regex = /^[Pp][0-9]{8}$/;
  return ptin_regex.test(ptin);
}


export function isValidFirmKey(firmKey: string) {
  const guid_regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guid_regex.test(firmKey);
}

export function isValidGuid(guid: string) {
  const guid_regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guid_regex.test(guid);
}

export function validatePhoneLength(phoneNoId: any) {
  const phoneNo = phoneNoId.trim();
  if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
    return false;
  } else return true;
}

export function validateFaxLength(FaxId: any) {
  const Fax = FaxId.trim();
  if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
    return false;
  } else return true;
}

export function validateValidCity(cityId: any) {
  const city = cityId.trim();
  if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(city) == false) {
    return false;
  } else return true;
}
export function validateZipLength(zipNoId: any) {
  const zipNo = zipNoId.trim();
  if (
    zipNo.trim().length > 5 ||
    zipNo.trim().length < 5 ||
    /^[0-9]{5}$/.test(zipNo) == false
  ) {
    return false;
  } else return true;
}
export function ValidateWebsite(website: string) {
  if (
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z A-Z 0-9]+([\-\.]{1}[a-z A-Z 0-9]+)*\.[a-z A-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
      website
    ) == false
  ) {
    return false;
  } else {
    return true;
  }
}

export function validateExtensionLength(extensionId: any) {
  const extension = extensionId.trim();
  if (extension.trim().length > 6 || /^[0-9]{1,10}$/.test(extension) == false) {
    return false;
  } else return true;
}
export function validatePasswordLength(passwordId: any) {
  const password = passwordId.trim();
  if (password.length < 8) {
    return false;
  }
  return true;
}
export function validatePasswordSpace(passwordId: any) {
  const password = passwordId.trim();
  if (password.indexOf(" ") >= 0) {
    return false;
  }
  return true;
}

export function ValidateTenDigitNumber(event: any) {
  const pattern = new RegExp(/^[0-9]{0,10}$/);
  return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
  const pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
  return pattern.test(value);
}

export function validateFileSize(file: any): boolean {
  if (file.size === 0) {
    return false;
  }
  if (file.size > FileConstants.MaximumFileSize) {
    AppNotifier.Error(FileSizeExceedsLimit(file.name));
    return false;
  }
  return true;
}

export const isMaxUploadSizeLimitExceeded = (totalSize: number, maxSize?: number): boolean => {
  const maximumSize = maxSize || FileConstants.MaximumTotalFileSize;
  if (totalSize === 0) {
      return false;
  }
  if (totalSize > maximumSize) {
      AppNotifier.Error(ValidationContants.FileSizeExceeded);
      return false;
  }
  return true;
};

export const FileSizeExceedsLimit = (fileName: string) =>
  `${fileName} cannot be uploaded since the size is greater than 3 GB.`;

export const validateFileType = (fileType: string, restrictedFileTypes: string[]) => restrictedFileTypes.includes(fileType) ? false : true;


export const getRestrictedFileMessage = (restrictedFileTypes: string[]) => {
  return `One or more files couldn't be uploaded due to these restricted file type(s): ${restrictedFileTypes.join()}`
};

export const validateDRLFileSize = (size: number, name: string): boolean => {
  if (size === 0) {
    return false;
  }
  if (size > Number(FileConstants.MaximumFileSize)) {
    AppNotifier.Error(FileSizeExceedsLimit(name));
    return false;
  }
  return true;
}