import { Action, Reducer } from "redux";
import { DispatchActions } from "../actions/tokenActions";
import { actionTypes } from "../types/tokenTypes";

export interface IRefreshToken {
    loading: boolean
}

const initialOtpPage: IRefreshToken = {
    loading: false
}


export const reducer: Reducer<IRefreshToken> =
    (prevState: IRefreshToken = initialOtpPage, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    let newState = { ...prevState };
    switch (action.type) {
        case actionTypes.REQUEST_REFRESH_TOKEN: newState.loading = true;
            break;
        case actionTypes.COMPLETED_REFRESH_TOKEN: newState.loading = false;
            break;
    }
    return newState;
}