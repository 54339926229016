import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "../index";
import { ErrorAction } from "./errorActions";
import { StatusType, NotificationAction } from "./notificationActions";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { actionTypes as errorTypes } from "../types/errorTypes";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { DisplayError, DownloadPageConstants } from "../../helper/Constants";
import { IFileUploadModel } from "../../core/viewModels/FileModel";
import { IDocumentRequestFile } from "../../core/viewModels/DocumentRequestModel";

export interface IUploadDocumentResponse {
    isSuccess: boolean;
    errorCode: string;
    errorDescription: string
};


const logger = TelemetryLogger.getInstance();

type KnownActions = ErrorAction | NotificationAction;

export const getDocumentRequestDetail = (
    clientId: string,
    handleDRLDetails?: (data: any) => void,
    handleError?: () => void
): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        axios.get<any>(`/api/DocumentRequestUpload/DocumentRequestDetail/${clientId}`)
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    handleDRLDetails && handleDRLDetails(result.data);
                } else {
                    let errorDesc = result.errorDescription;
                    let errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: errorTypes.SET_ERROR_MSG,
                            data: errorDesc,
                        });

                        handleError && handleError();
                    } else {
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });
                    }
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: DownloadPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error,
                });
                logger.trackError(
                    `getting document details failed in Document Request with error ${error.message} for clientId: ${clientId}`
                );
            })
    }


export const fetchUploadDocumenttSasLink =
    (
        documentGuid: string,
        clientId: string,
        callback?: any,
        requestedDate?: Date,
        errorCallBack?: any
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            axios
                .get(
                    `/api/DocumentRequestUpload/DocumentUploadLink/${clientId}?fileGuid=${documentGuid}&createdDate=${requestedDate}`
                )
                .then((response: any) => {
                    const result = response.data;

                    if (result.isSuccess) {
                        const data = {
                            guid: documentGuid,
                            sas: result.data,
                        };
                        callback && callback(data);
                    }
                    else {
                        let errorDesc = result.errorDescription;
                        dispatch({
                            type: notificationTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });
                        callback && callback(errorDesc);
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `fetchUploadAttachmentSasLink failed in dropoff with error ${error.message} for clientId: ${clientId} and documentGuid: ${documentGuid}`
                    );
                    errorCallBack();
                });
        };

export const addFileToDocument =
    (
        documentRequestFileId: number,
        clientId: string,
        fileToUpload: any,
        handleSuccess?: (msg: string) => void,
        handleError?: (msg: string, code: string) => void
    ): AppThunkAction<KnownActions> =>
        (dispatch) => {
            axios
                .post(
                    `/api/DocumentRequestUpload/UploadDocument/${clientId}?documentRequestFileId=${documentRequestFileId}`,
                    { ...fileToUpload, fileGuid: fileToUpload.fileGUID, isUploaded: true, fileSize: fileToUpload.size }
                )
                .then(function (response: AxiosResponse<IUploadDocumentResponse>) {
                    let result = response.data;
                    if (response.data.isSuccess) {
                        handleSuccess && handleSuccess(result.toString());
                    } else {
                        let errorDesc = result.errorDescription;
                        let errorCode = result.errorCode;
                        handleError && handleError(errorDesc.toString(), errorCode.toString());
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `uploadFile failed with error ${error.message} for clientId: ${clientId} `
                    );
                });
        };

export const deleteAllRequestedFiles = (
    id: string,
    sentDate: Date,
    fileData?: IDocumentRequestFile,
    handleSuccsess?: (uri: string) => void,
    handleError?: (msg: string) => void)
    : AppThunkAction<KnownActions> =>
    (dispatch) => {
        return axios
            .delete<any>(`/api/DocumentRequestUpload/DeleteAllDocumentFiles/${id}?sentDate=${sentDate}`, { data: { ...fileData, isUploaded: true } })
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    handleSuccsess && handleSuccsess(result);
                } else {
                    let errorDesc = result.errorDescription;
                    handleError && handleError(errorDesc);
                }
            })
            .catch(function (error: any) {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: DownloadPageConstants.ErrorMessage.serverError, statusType: StatusType.Error, });
                logger.trackError(
                    `deleteAllFiles failed with error ${error.message} for clientId: ${id} `
                );
            });
    };

export const deleteRequestedFile = (
    id: string,
    sentDate: Date,
    fileData: IFileUploadModel,
    handleSuccsess?: (uri: string) => void,
    handleError?: (msg: string) => void)
    : AppThunkAction<KnownActions> =>
    (dispatch) => {
        return axios
            .delete<any>(`/api/DocumentRequestUpload/DeleteDocumentFile/${id}?sentDate=${sentDate}`, { data: { ...fileData, isUploaded: true } })
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    handleSuccsess && handleSuccsess(result);
                } else {
                    let errorDesc = result.errorDescription;
                    handleError && handleError(errorDesc);
                }
            })
            .catch(function (error: any) {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: DownloadPageConstants.ErrorMessage.serverError, statusType: StatusType.Error, });
                logger.trackError(
                    `deleteFile failed with error ${error.message} for clientId: ${id} `
                );
            });
    };
export const completeUpload = (
    clientId: string,
    documentRequestId: number,
    handleSuccsess?: (uri: string) => void,
    handleError?: (msg: string) => void)
    : AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        return axios
            .put<any>(`/api/DocumentRequestUpload/CompleteDocumentRequest/${clientId}?documentRequestId=${documentRequestId}`)
            .then(function (response: any) {
                let result = response.data;
                if (result.isSuccess) {
                    handleSuccsess && handleSuccsess(result);
                } else {
                    let errorDesc = result.errorDescription;
                    handleError && handleError(errorDesc);
                }
            })
            .catch(function (error: any) {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: DownloadPageConstants.ErrorMessage.serverError, statusType: StatusType.Error, });
                logger.trackError(
                    `document submit failed with error ${error.message} for clientId: ${clientId} `
                );
            });
    };