export interface ISentMessageRecipientModel {
    sentMessageRecipientId: number;
    sentMessageId: number;
    recipientId?: number;
    email: string;
    firstName: string
    lastName: string;
    expiresDays: number
    deletedByRecipient: number;
    securityAnswer: string;
    securityQuestion: string;
    isLinkLocked: boolean;
}

export const initialSentMessageRecipient: ISentMessageRecipientModel = {
    sentMessageRecipientId: 0,
    sentMessageId: 0,
    email: "",
    firstName: "",
    lastName: "",
    expiresDays: 0,
    deletedByRecipient: 0,
    securityAnswer: "",
    securityQuestion: "",
    isLinkLocked: false
}
