export enum actionTypes {
    REQUEST_DROPOFF_RECIPIENTS = "request_dropoff_recipients",
    RECEIVED_DROPOFF_RECIPIENTS = "received_dropoff_recipients",
    COMPLETED_DROPOFF_RECIPIENTS = "completed_dropoff_recipients",

    REQUEST_SEND_DROPOFF_MESSAGE = "request_send_dropoff_message",
    RECEIVED_SEND_DROPOFF_MESSAGE = "received_send_dropoff_message",
    COMPLETED_SEND_DROPOFF_MESSAGE = "completed_send_dropoff_message",

    REQUEST_REMOVE_ATTACHMENT = "request_remove_attachment",
    COMPLETED_REMOVE_ATTACHMENT = "completed_remove_attachment",

    REQUEST_DROPOFF_SETTINGS = "request_dropoff_settings",
    RECEIVED_DROPOFF_SETTINGS = "received_dropoff_settings",
    COMPLETED_DROPOFF_SETTINGS = "completed_dropoff_settings",

    REQUEST_DROP_OFF_PAGE_INFO = "request_drop_off_page_info",
    RECEIVED_DROP_OFF_PAGE_INFO = "received_drop_off_page_info",

    REQUEST_DROP_OFF_LINK = "request_drop_off_link",
    RECEIVED_DROP_OFF_LINK = "received_drop_off_link",
}