import { RecipientType } from "../common/enums";

export interface IDropOffRecipient {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    isDefault: boolean;
  }

  export const initialDropOffRecipient: IDropOffRecipient = {
    userId: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    isDefault: false,
  };

  export interface ISentMessageRecipientModel {
    sentMessageRecipientId: number;
    sentMessageId: number;
    recipientId?: number;
    companyId: number;
    email: string;
    firstName: string;
    lastName: string;
    expiresDays: number;
    deletedByRecipient: number;
    isFilesDownloaded: boolean;
    folderId: number;
    fileRetryCount: number;
    accessDate: Date;
    securityAnswer: string;
    securityQuestion: string;
    isLinkLocked: number;
    recipientType: RecipientType;
  }
  
  export const initialSentMessageRecipientModel: ISentMessageRecipientModel = {
    sentMessageRecipientId: 0,
    sentMessageId: 0,
    recipientId: 0,
    companyId: 0,
    email: "",
    firstName: "",
    lastName: "",
    expiresDays: 0,
    deletedByRecipient: 0,
    isFilesDownloaded: false,
    folderId: 2,
    fileRetryCount: 0,
    accessDate: new Date(),
    securityAnswer: "",
    securityQuestion: "",
    isLinkLocked: 0,
    recipientType: RecipientType.ToRecipient,
  };


  export interface IDropOffPageInfo {
    personalDropOffUserId: number;
  }

  export interface IDropOffSettings {
    isEmailAddressLookupAllowed: boolean;
    isPersonalDropOffLinkToDisplay: boolean;
    isDownloadNotificationToSend: boolean;
    isSpamFilteringRequired: boolean;
    restrictedFileTypes: string[];
    dropOffRecipients : IDropOffRecipient[];
  }

  export const initialDropOffSettings : IDropOffSettings = {
    isEmailAddressLookupAllowed: false,
    isPersonalDropOffLinkToDisplay: false,
    isDownloadNotificationToSend: false,
    isSpamFilteringRequired: false,
    restrictedFileTypes: [],
    dropOffRecipients : []
  }