import React, { ReactNode } from "react";
import { Modal, ModalHeader } from "react-bootstrap";

interface ICustomModalProps {
	children: ReactNode;
	show: boolean;
	cancelButtonName?: string;
	confirmButtonName?: string;
	additionalActionButtonName?: string;
	title?: string;
	onSubmit: () => void;
	onHide: () => void;
	size?: any; // any is required since size type can not be given as string
	className?: string;
}

export const CustomModal: React.FC<ICustomModalProps> = (props) => {

	return (
		<Modal
			className={props.className ? props.className : "custom-modal-container"}
			show={props.show}
			onHide={props.onHide}
			onClick={(e: any) => { e.stopPropagation(); }}
		>
			<ModalHeader closeButton closeVariant="black">
				<Modal.Title>
					{props.title}
				</Modal.Title>
			</ModalHeader>
			<Modal.Body>
				{props.children}
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="button-primary-grey" onClick={props.onHide}>{props.cancelButtonName}</button>
				<button type="button" className="button-primary-blue-2" onClick={props.onSubmit}>{props.confirmButtonName}</button>
			</Modal.Footer>
		</Modal>
	);
};