import axios, { AxiosRequestConfig } from "axios";
import { AppNotifier } from "./../../helper/AppNotifier";
import { logger } from "../../App";
import { LogEventConstants } from "../../helper/Constants";

export interface IFileToUpload {
    maxBlockSize: number,
    file: File,
    size: number,
    uploadFailed: boolean,
    bytesUploaded: number,
    sasUrl: string,
    completeEvent: number,
    blockIds: string[],
    uploadedIds: number[],
    filecontent: File[],
    blockIdPrefix: string,
    numberOfBlocks: number,
    fileName: string,
    blockUploaded: number[],
    fileGUID: string,
    nextItemtoUpload: number
}

export function uploadFile(
    fileObject: File,
    sas: any,
    fileName: string,
    uploadProgressCallback?: any,
    uploadCompleteCallback?: (fileToUpload: IFileToUpload, errorMessage?: string) => void
) {
    const fileToUpload: IFileToUpload = {
        maxBlockSize: 0,
        file: new File([], ""),
        size: 0,
        uploadFailed: false,
        bytesUploaded: 0,
        sasUrl: "",
        completeEvent: 0,
        blockIds: [],
        uploadedIds: [],
        filecontent: [],
        blockUploaded: [],
        blockIdPrefix: "",
        numberOfBlocks: 0,
        fileName: "",
        fileGUID: "",
        nextItemtoUpload: 0
    };
    fileToUpload.maxBlockSize = 4096 * 1024;
    fileToUpload.file = fileObject;
    fileToUpload.size = fileObject.size;
    fileToUpload.uploadFailed = false;
    fileToUpload.bytesUploaded = 0;
    fileToUpload.fileGUID = sas.guid;
    if (fileToUpload.size < fileToUpload.maxBlockSize) {
        fileToUpload.maxBlockSize = fileToUpload.size;
    }
    if (fileToUpload.size % fileToUpload.maxBlockSize == 0) {
        fileToUpload.numberOfBlocks = fileToUpload.size / fileToUpload.maxBlockSize;
    } else {
        fileToUpload.numberOfBlocks =
            parseInt((fileToUpload.size / fileToUpload.maxBlockSize).toString(), 10) +
            1;
    }
    fileToUpload.sasUrl = sas.sas;
    fileToUpload.blockIdPrefix = "block-";
    fileToUpload.fileName = fileName;
    uploadFileInBlocks(
        fileToUpload,
        uploadProgressCallback,
        uploadCompleteCallback
    );
}

function uploadFileInBlocks(
    fileToUpload: IFileToUpload,
    uploadProgressCallback?: any,
    uploadCompleteCallback?: (fileToUpload: IFileToUpload, errorMessage?: string) => void
) {
    for (let i = 0; i < fileToUpload.numberOfBlocks; i++) {
        let fileContent;
        if (i == fileToUpload.numberOfBlocks - 1)
            fileContent = fileToUpload.file.slice(i * fileToUpload.maxBlockSize);
        else
            fileContent = fileToUpload.file.slice(
                i * fileToUpload.maxBlockSize,
                (i + 1) * fileToUpload.maxBlockSize
            );

        const blockId =
            fileToUpload.blockIdPrefix + pad(fileToUpload.blockIds.length, 6);
        fileToUpload.blockIds.push(btoa(blockId));
        fileToUpload.filecontent.push(new File([fileContent], fileToUpload.fileName));
    }
    let ajaxcaller = 10;
    if (fileToUpload.numberOfBlocks < 10)
        ajaxcaller = fileToUpload.numberOfBlocks;
    for (let i = 0; i < ajaxcaller; i++) {
        fileToUpload.nextItemtoUpload = ajaxcaller;
        initReaderObject(
            fileToUpload,
            fileToUpload.blockIds[i],
            fileToUpload.filecontent[i],
            i,
            uploadProgressCallback,
            uploadCompleteCallback
        );
    }
}

function pad(number: number, length: number) {
    let str = "" + number;
    while (str.length < length) {
        str = "0" + str;
    }
    return str;
}

function initReaderObject(
    fileToUpload: IFileToUpload,
    blockId: any,
    fileContent: any,
    blockIndex: number,
    uploadProgressCallback?: any,
    uploadCommittCallBack?: (fileToUpload: IFileToUpload, errorMessage?: string) => void
) {
    const readerObject: any = new FileReader();
    readerObject.onloadend = function (evt: any) {
        if (evt.target.readyState == 2) {
            // DONE == 2
            const uri = fileToUpload.sasUrl + "&comp=block&blockid=" + blockId;
            const requestData = new Uint8Array(evt.target.result);
            let encryptedFileName = "";
            try {
                encryptedFileName = btoa(fileToUpload.file.name);
            } catch (exp) {
                try {
                    encryptedFileName = btoa(encodeURIComponent(fileToUpload.file.name));
                } catch (exp) {
                    encryptedFileName = fileToUpload.file.name.replace(/\W/g, "");
                    encryptedFileName = btoa(encryptedFileName);
                }
            }

            const config: AxiosRequestConfig = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "x-ms-blob-type": "BlockBlob",
                    "x-ms-blob-content-disposition":
                        "attachment;filename=" + "'" + encryptedFileName + "'",
                    "x-ms-meta-filename": fileToUpload.fileName,
                    "x-ms-meta-filetype": fileToUpload.file.type,
                },
                onUploadProgress: (e) => {
                    if (e.progress) {
                        fileToUpload.blockUploaded[blockIndex] = e.loaded;
                        if (typeof uploadProgressCallback == "function") {
                            const blockUploadedSize = fileToUpload.blockUploaded.reduce(
                                (a: number, b: number) => a + b,
                                0
                            );
                            const percent = (blockUploadedSize / fileToUpload.size) * 100;
                            uploadProgressCallback(percent, fileToUpload);
                        } else {
                            if (uploadProgressCallback) {
                                AppNotifier.Error("Error in file upload status update");
                            }
                        }
                    }
                },
            };

            axios.put(uri, requestData, config).then(() => {
                fileToUpload.bytesUploaded += requestData.length;
                fileToUpload.uploadedIds.push(blockId);
                if (fileToUpload.blockIds[fileToUpload.nextItemtoUpload]) {
                    initReaderObject(
                        fileToUpload,
                        fileToUpload.blockIds[fileToUpload.nextItemtoUpload],
                        fileToUpload.filecontent[fileToUpload.nextItemtoUpload],
                        fileToUpload.nextItemtoUpload,
                        uploadProgressCallback,
                        uploadCommittCallBack
                    );
                    fileToUpload.nextItemtoUpload = fileToUpload.nextItemtoUpload + 1;
                } else {
                    if (fileToUpload.uploadedIds.length === fileToUpload.blockIds.length)
                        commitBlockList(fileToUpload, uploadCommittCallBack);
                }
            }, (err: any) => {
                logger.trackError(
                    `${LogEventConstants.Dropoff.FileUploader.PageTitle} - upload failed for fileToUpload: ${JSON.stringify(fileToUpload)} with error ${JSON.stringify(err)}`);
                fileToUpload.uploadFailed = true;
                uploadCommittCallBack && uploadCommittCallBack(fileToUpload,
                    fileToUpload.uploadFailed ? `An error occurred while uploading ${fileToUpload.fileName}. Please try again.` : undefined);
            });
        }
    };
    readerObject.readAsArrayBuffer(fileContent);
}

function commitBlockList(fileToUpload: any, uploadCommittCallBack?: any) {
    const uri = fileToUpload.sasUrl + "&comp=blocklist";
    let requestBody = "<?xml version='1.0' encoding='utf-8'?><BlockList>";
    for (let i = 0; i < fileToUpload.blockIds.length; i++) {
        requestBody += "<Latest>" + fileToUpload.blockIds[i] + "</Latest>";
    }
    requestBody += "</BlockList>";
    let encryptedFileName = "";
    try {
        encryptedFileName = btoa(fileToUpload.fileName);
    } catch (exp) {
        try {
            encryptedFileName = btoa(encodeURIComponent(fileToUpload.fileName));
        } catch (exp) {
            encryptedFileName = fileToUpload.fileName.replace(/\W/g, "");
            encryptedFileName = btoa(encryptedFileName);
        }
    }

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "x-ms-blob-content-disposition": `attachment;filename="${encryptedFileName}"`,
            "x-ms-meta-filename": fileToUpload.fileName,
            "x-ms-meta-filetype": fileToUpload.file.type,
        },
    };

    axios.put(uri, requestBody, config).then(() => {
        if (
            uploadCommittCallBack != undefined &&
            typeof uploadCommittCallBack == "function"
        )
            uploadCommittCallBack(fileToUpload);
    });
}
