import React, { FC } from "react";
import OtpInput from "react-otp-input";

interface IOtpInputControlProps {
    otp: string;
    setOtp: (otp: string) => void;
    isInvalid?: boolean;
    errorText?: string;
}

const OtpInputControl: FC<IOtpInputControlProps> = ({ otp, setOtp, isInvalid, errorText }) => {
    return (
        <div className={`otp-input-control-wrapper${isInvalid && otp.length === 0 ? " error" : ""}`}>
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={8}
                inputType={"tel"}
                containerStyle="input-container"
                inputStyle={{ width: 34 }}
                renderInput={(props) => <input {...props} />}
            />
            {isInvalid && <>
                {errorText && <div className="invalid-feedback">{errorText}</div>}
            </>
            }
        </div>
    );
}

export default OtpInputControl;