import {
    ApplicationInsights,
    ITelemetryItem,
    DistributedTracingModes,
    SeverityLevel,
    IEventTelemetry,
    Util,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import axios from "axios";
import { AppInsightsConstants, LinkType } from "../../helper/Constants";
import { ApplicationInsightsVM, ILogger } from "./Logger";

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory({ basename: "" });
let profileData: ApplicationInsightsVM;
let inProgress: boolean;
let appInsightClientId: string;

export class TelemetryLogger implements ILogger {
    private static instance: TelemetryLogger;
    private static appInsights: ApplicationInsights;
    private static pageViewRefTime: any;

    private constructor() {
        TelemetryLogger.appInsights = appInsights;
    }

    public static getInstance(): TelemetryLogger {
        if (!TelemetryLogger.instance) {
            this.instance = new TelemetryLogger();
        }
        return TelemetryLogger.instance;
    }

    public trackPageView = (pageName: string) => {
        TelemetryLogger.appInsights.context.telemetryTrace.name = pageName;
        TelemetryLogger.appInsights.context.telemetryTrace.traceID =
            Util.generateW3CId();
        TelemetryLogger.appInsights.trackPageView({
            name: pageName,
            properties: {
                duration: new Date().getTime() - TelemetryLogger.pageViewRefTime,
            },
        });
        TelemetryLogger.pageViewRefTime = new Date().getTime();
    };

    public trackTrace = (message: string) => {
        TelemetryLogger.appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Information,
        });
    };

    public trackError = (message: string) => {
        TelemetryLogger.appInsights.trackException({
            exception: new Error(message),
        });
    };

    public trackEvent = (traceEvent: IEventTelemetry) => {
        TelemetryLogger.appInsights.trackEvent(traceEvent);
    };

    public buildEvent = (eventName: string, properties: any): IEventTelemetry => {
        const traceEvent: IEventTelemetry = {
            name: eventName,
            properties: properties,
        };
        return traceEvent;
    };
}

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "",
        disableInstrumentationKeyValidation: true,
        extensions: [reactPlugin],
        disableFetchTracking: false,
        disableAjaxTracking: false,
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCorrelationHeaders: false,
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": AppInsightsConstants.Role });

    if (profileData !== undefined) {
        envelope.tags.push({ "ai.user.accountId": profileData.companyId });
        envelope.tags.push({ "ai.user.id": appInsightClientId });
    }

    var telemetryItem = envelope.baseData;
    if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {};
        telemetryItem.properties["CompanyId"] = profileData.companyId;
        telemetryItem.properties["CompanyName"] = profileData.companyName;
        telemetryItem.properties["Product"] = AppInsightsConstants.Product;
        telemetryItem.properties["MessageGuid"] = decodeURIComponent(Array.prototype.map.call(atob(profileData.messageGuid), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''));
        telemetryItem.properties["MessageId"] = profileData.messageId;

        let userId: string = localStorage.getItem("userId");
        let messageGuid: string = localStorage.getItem("clientId");
        if (userId && messageGuid) {
            if (messageGuid == telemetryItem.properties["MessageGuid"]
                && userId != telemetryItem.properties["RecipientId"])
                telemetryItem.properties["RecipientId"] = userId;
        }
    }
});
//TODO: Provide TPLinktype parameter
const initializeAppInsights = (clientId: string, componentName: string, type: LinkType = LinkType.SendLink) => {
    if (clientId === undefined) return;
    if (
        !inProgress &&
        (profileData === undefined || appInsightClientId !== clientId)
    ) {
        inProgress = true;
        axios
            .get<any>(`/api/ApplicationInsight/BasicDetails/${clientId}?component=${componentName}&tPLinkType=${type}`)
            .then(function (response: any) {
                profileData = response.data;
                appInsights.config.instrumentationKey = profileData.instrumentationKey;
                inProgress = false;
                appInsightClientId = clientId;
            })
            .catch(function (error: any) {
                inProgress = false;
            });
    }
};

export { initializeAppInsights, reactPlugin, appInsights };
