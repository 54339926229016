import React, { FC, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AccessCardMenuItems, DocumentRequestMeuItems, LinkType, LogEventConstants } from "../../helper/Constants";
import AccessLinkContent from "./AccessLinkContent";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { accessLinkParams } from "../SendLink";
import { requestScreen } from "../../store/actions/sendLinkActions";
import { logger } from "../../App";
import { isRequestLink } from "../../helper/HelperFunctions";
import "./AccessLinkLayout.scss";
import { StepWizard } from "../common/StepWizard";
interface IAccessLinkLayoutProps {
    type: LinkType
}
const AccessLinkLayout: FC<IAccessLinkLayoutProps> = ({ type }) => {
    const menuKeys = !isRequestLink(type) ? AccessCardMenuItems : DocumentRequestMeuItems;
    const [activeMenu, setActiveMenu] = useState(menuKeys[0]);
    const [activeMenuIndex, setActiveMenuIndex] = useState(0);
    const [previousLocationClientId, setPreviousLocationClientId] = useState("");
    const isScreenLoadCompleted = useSelector((state: ApplicationState) => state.sendLink.isRequestScreenCompleted);
    const { clientId }: accessLinkParams = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const pageTitle = !isRequestLink(type) ? "AccessLinkLayout page" : "DocumentRequestLayout Page";

    const getScreen = () => {
        dispatch(
            requestScreen(
                clientId,
                () => { },
                () => history.push(`/Error`),
                type
            )
        );
    };

    useEffect(() => {
        logger.trackPageView(pageTitle);
        logger.trackEvent(
            logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
                page: pageTitle,
                clientId: clientId,
            })
        );
        getScreen();
        if (activeMenuIndex === 0 && sessionStorage.getItem("previousClientId")) {
            const previousClientId = sessionStorage.getItem("previousClientId");
            history.push(`/${!isRequestLink(type) ? "SendLinkRedirect" : "DocumentRequest"}/${previousClientId}`);
        }

        return () => {
            sessionStorage.removeItem("previousClientId");
        }
    }, []);

    return (
        isScreenLoadCompleted ?
            <div className={`access-link-container ${activeMenu === "Upload" ? "upload-page-container" : ""}`}>
                <Row className="access-link-header">
                   <StepWizard 
                        menuKeys={menuKeys}
                        activeMenuIndex={activeMenuIndex}
                   />
                </Row>
                <Row className={activeMenuIndex === 2 ? "access-link-content" : ""}>
                    <AccessLinkContent
                        type={type}
                        eventKey={activeMenu}
                        previousLocationClientId={previousLocationClientId}
                        setPreviousLocationClientId={setPreviousLocationClientId}
                        setActiveMenu={setActiveMenu}
                        setActiveMenuIndex={setActiveMenuIndex} />
                </Row>
            </div>
            : <LoadingOverlay>
                <Loader text="" loading={true} />
            </LoadingOverlay >
    )
}

export default AccessLinkLayout;