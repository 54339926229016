import { initialUserModel, IUserModel } from "./UserViewModel";

export interface IHeaderInfo {
    companyName: string;
    contactPerson: IUserModel;
}

export const initialHeaderInfo: IHeaderInfo= {
    companyName: "",
    contactPerson: initialUserModel
}
