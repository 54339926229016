import React, { FC } from "react";
import { LinkType } from "../../helper/Constants";
import FileDownload from "../FileDownload";
import OTPValidation from "../OTPValidation";
import QAValidation from "../QAValidation";
import SendLink from "../SendLink";
import Upload from "../Upload";

interface IAccessLinkContentProps {
  type: LinkType;
  eventKey: string;
  previousLocationClientId: string;
  setPreviousLocationClientId: React.Dispatch<React.SetStateAction<string>>;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
  setActiveMenuIndex: React.Dispatch<React.SetStateAction<number>>
}

const AccessLinkContent: FC<IAccessLinkContentProps> = (props) => {
  let content;
  switch (props.eventKey) {
    case "Verify":
      content = <SendLink {...props}></SendLink>
      break;
    case "AccessCode":
      content = <OTPValidation {...props}></OTPValidation>;
      break;
    case "QnA":
      content = <QAValidation {...props}></QAValidation>;
      break;
    case "Download":
      content = <FileDownload {...props}></FileDownload>;
      break;
    case "Upload":
      content = <Upload {...props} />;
      break;
    default:
      content = <></>;
  }
  return content;
};

export default AccessLinkContent;